import { FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function PrinterIndex() {
	return FetchRequestGET(`/printers`)
}

export async function PrinterShow(id: number) {
	return FetchRequestGET(`/printers/${id}`)
}

export async function PrinterUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/printers/${id}`,
		body
	)
}

export async function PrinterCreate(body: any) {
	return FetchRequestPOST(
		`/printers`,
		body
	)
}
