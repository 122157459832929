import { useEffect, useState } from "react";
import { UserCreatePaymentIntent, UserGetMe, UserUpdateMe } from "../../lib/backend/user";
import { User } from "../../../../backend/models/user";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog-small";
import { X } from "lucide-react";
import { ChangePasswordUpdate } from "../../lib/backend/auth";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from "../../lib/environment";
import CheckoutForm from "./CheckoutForm";
import { useLocation, useSearchParams } from "react-router-dom";

const stripePromise = loadStripe(stripePublicKey());

// console.log(stripePublicKey())

type UserType = User & { account_status: string }

const australian_states = [
	{ value: "Australian Capital Territory", label: "Australian Capital Territory" },
	{ value: "New South Wales", label: "New South Wales" },
	{ value: "Northern Territory", label: "Northern Territory" },
	{ value: "Queensland", label: "Queensland" },
	{ value: "South Australia", label: "South Australia" },
	{ value: "Tasmania", label: "Tasmania" },
	{ value: "Victoria", label: "Victoria" },
	{ value: "Western Australia", label: "Western Australia" },
]

export default function SettingsPage() {

	const [initialLoading, setInitialLoading] = useState(true)
	const [data, setData] = useState<any>()
	const [originalData, setOriginalData] = useState<any>()
	const [showPasswordModal, setShowPasswordModal] = useState(false)
	const [showBuyLicenseModal, setShowBuyLicenseModal] = useState(false)
	const [showAFCCModal, setShowAFCCModal] = useState(false)
	const [showCelebranteModal, setShowCelebranteModal] = useState(false)
	const [showSocietyModal, setShowSocietyModal] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams();

	const { toast } = useToast()

	useEffect(() => {
		getSettingsData()
	}, [])

	async function getSettingsData() {
		//
		const response = await UserGetMe()
		if (response.ok) {
			const data = await response.json()
			//
			setData(data)
			setOriginalData(data)
			setInitialLoading(false)
		}
		//
	}

	async function saveSettings() {
		//
		const response = await UserUpdateMe({ user: data })
		if (response.ok) {
			//
			setOriginalData(data)
			//
			toast({
				// title: "Settings Saved",
				duration: 1500,
				description: <div className=" h-auto w-full text-xl text-green-600 font-bold">Your settings have been saved</div>,
			})
			//
		} else {
			//
			toast({
				// title: "Settings Saved",
				duration: 1500,
				description: <div className=" h-auto w-full text-xl text-red-600 font-bold">Your settings could not be saved</div>,
			})
			//
		}
		//
	}

	async function getAFCCChangeResult(result: boolean) {
		if (result === true) {
			const copyData = JSON.parse(JSON.stringify(data))
			copyData.membership = "AFCC"
			setData(copyData)
		}
		setShowAFCCModal(false)
	}

	async function getCelebranteChangeResult(result: boolean) {
		if (result === true) {
			const copyData = JSON.parse(JSON.stringify(data))
			copyData.membership = "Celebrante"
			setData(copyData)
		}
		setShowCelebranteModal(false)
	}

	async function getSocietyChangeResult(result: boolean) {
		if (result === true) {
			const copyData = JSON.parse(JSON.stringify(data))
			copyData.membership = "The Celebrant Society"
			setData(copyData)
		}
		setShowSocietyModal(false)
	}

	return (
		<div className="px-2 mt-4">
			<div>
				<div className="text-2xl font-semibold mb-4 flex justify-between">
					<h1 className="text-3xl font-bold">
						Settings
					</h1>
				</div>
				<div className="w-20 h-20 mt-8" hidden={!initialLoading}>
					<svg aria-hidden="true" className="w-full text-left h-full m-0 mt-6 mb-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
				<div
					hidden={initialLoading}
					className="mt-8 w-full"
				>
					{!initialLoading && <SettingsForm
						originalData={originalData}
						data={data}
						setData={setData}
						saveSettings={saveSettings}
						setShowPasswordModal={setShowPasswordModal}
						setShowBuyLicenseModal={setShowBuyLicenseModal}
						setShowAFCCModal={setShowAFCCModal}
						setShowCelebranteModal={setShowCelebranteModal}
						setShowSocietyModal={setShowSocietyModal}
					/>}
				</div>
				{showPasswordModal && <PasswordModal setShowPasswordModal={setShowPasswordModal} />}
				{showBuyLicenseModal && <BuyLicenseModal setShowBuyLicenseModal={setShowBuyLicenseModal} data={data} />}
				{showAFCCModal && <AFCCConfirmModal setShowAFCCModalResult={getAFCCChangeResult} />}
				{showCelebranteModal && <CelebranteConfirmModal setShowCelebranteModalResult={getCelebranteChangeResult} />}
				{showSocietyModal && <SocietyConfirmModal setShowSocietyModalResult={getSocietyChangeResult} />}
			</div>
		</div>
	)
}

function SettingsForm(props: { originalData: UserType, data: UserType, setData: (data: UserType) => void, saveSettings: () => void, setShowPasswordModal: any, setShowBuyLicenseModal: any, setShowAFCCModal: any, setShowCelebranteModal: any, setShowSocietyModal: any }) {
	// console.log(props.data)

	const SaveButton = () => <Button
		onClick={props.saveSettings}
		disabled={JSON.stringify(props.originalData) === JSON.stringify(props.data)}
	>
		Save Settings
	</Button>

	return (
		<div className="w-full max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-3">
			<div className="col-span-2 mb-4">
				<SaveButton />
			</div>
			<Card className="col-span-2 md:col-span-1">
				<CardContent>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="email"
							className="text-base text-gray-600 font-light"
						>
							Email (Lowercase)
						</Label>
						<Input
							id="email"
							type="text"
							name="email"
							onChange={(e) => props.setData({ ...props.data, email: e.target.value.toLowerCase() })}
							value={props.data.email}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
							autoFocus
						/>
						<div className="text-gray-600 font-light text-sm">
							This is the email address that will be shown on invoices AND the email address used to log in to your account
						</div>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="first_name"
							className="text-base text-gray-600 font-light"
						>
							Given Names
						</Label>
						<Input
							id="first_name"
							type="text"
							name="first_name"
							onChange={(e) => props.setData({ ...props.data, first_name: e.target.value })}
							value={props.data.first_name}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="last_name"
							className="text-base text-gray-600 font-light"
						>
							Last Name
						</Label>
						<Input
							id="last_name"
							type="text"
							name="last_name"
							onChange={(e) => props.setData({ ...props.data, last_name: e.target.value })}
							value={props.data.last_name}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="witness_qualification"
							className="text-base text-gray-600 font-light"
						>
							Celebrant Qualification
						</Label>
						<Input
							id="witness_qualification"
							type="text"
							name="witness_qualification"
							onChange={(e) => props.setData({ ...props.data, witness_qualification: e.target.value })}
							value={props.data.witness_qualification}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="apply_qualification_to_noim"
							className="text-base text-gray-600 font-light mt-5"
						>
							Apply Qualification to NOIM?
						</Label>
						<Select
							name="apply_qualification_to_noim"
							value={props.data.apply_qualification_to_noim}
							onValueChange={(value) => props.setData({ ...props.data, apply_qualification_to_noim: value === "Yes" ? "Yes" : "No" })}
						>
							<SelectTrigger
								id="apply_qualification_to_noim"
								data-testid="apply_qualification_to_noim"
								className="w-full bg-gray-200 text-lg"
								name="apply_qualification_to_noim"
							>
								<SelectValue placeholder="No" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								<SelectItem value="No" className="text-lg">No</SelectItem>
								<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="apply_qualification_to_form15"
							className="text-base text-gray-600 font-light mt-5"
						>
							Apply Qualification to Form 15?
						</Label>
						<Select
							name="apply_qualification_to_form15"
							value={props.data.apply_qualification_to_form15}
							onValueChange={(value) => props.setData({ ...props.data, apply_qualification_to_form15: value === "Yes" ? "Yes" : "No" })}
						>
							<SelectTrigger
								id="apply_qualification_to_form15"
								data-testid="apply_qualification_to_form15"
								className="w-full bg-gray-200 text-lg"
								name="apply_qualification_to_form15"
							>
								<SelectValue placeholder="No" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								<SelectItem value="No" className="text-lg">No</SelectItem>
								<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="apply_qual_to_dec"
							className="text-base text-gray-600 font-light mt-5"
						>
							Apply Qualification to Dec/Cert?
						</Label>
						<Select
							name="apply_qual_to_dec"
							value={props.data.apply_qual_to_dec}
							onValueChange={(value) => props.setData({ ...props.data, apply_qual_to_dec: value === "Yes" ? "Yes" : "No" })}
						>
							<SelectTrigger
								id="apply_qual_to_dec"
								data-testid="apply_qual_to_dec"
								className="w-full bg-gray-200 text-lg"
								name="apply_qual_to_dec"
							>
								<SelectValue placeholder="No" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								<SelectItem value="No" className="text-lg">No</SelectItem>
								<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="membership"
							className="text-base text-gray-600 font-light mt-5"
						>
							Member Sites
						</Label>
						<Select
							name="membership"
							value={props.data.membership}
							onValueChange={(value) => {
								if (value === "Standard Member") {
									props.setData({ ...props.data, membership: "Standard Member" })
								} else if (value === "AFCC") {
									// props.setShowAFCCModal(true)
									props.setData({ ...props.data, membership: "AFCC" })
								} else if (value === "Celebrante") {
									props.setShowCelebranteModal(true)
									// props.setData({ ...props.data, membership: "Celebrante" })
								} else if (value === "The Celebrant Society") {
									props.setShowSocietyModal(true)
									// props.setData({ ...props.data, membership: "The Celebrant Society" })
								}
							}}
						>
							<SelectTrigger
								id="membership"
								data-testid="membership"
								className="w-full bg-gray-200 text-lg"
								name="membership"
							>
								<SelectValue placeholder="No" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								<SelectItem value="Standard Member" className="text-lg">Standard Member</SelectItem>
								<SelectItem value="AFCC" className="text-lg">AFCC</SelectItem>
								<SelectItem value="Celebrante" className="text-lg">Celebrante</SelectItem>
								<SelectItem value="The Celebrant Society" className="text-lg">The Celebrant Society</SelectItem>
							</SelectContent>
						</Select>
					</div>

				</CardContent>
			</Card>

			<Card className="col-span-2 md:col-span-1">
				<CardContent>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="account_status"
							className="text-base text-gray-600 font-light"
						>
							Account Status
						</Label>
						<Input
							id="account_status"
							type="text"
							name="account_status"
							disabled
							value={props.data.account_status}
							className={"w-full text-lg p-0 m-0 pl-2 ring-offset-0 font-normal" + (props.data.status === "I" ? "text-red-700" : "")}
						/>
					</div>
					{props.data.account_status === "Inactive" && <div className="grid w-full items-center gap-1.5 mt-5">
						<Button
							className="w-full bg-green-500 text-white hover:bg-green-600"
							onClick={() => props.setShowBuyLicenseModal(true)}
						>
							Buy a License
						</Button>
					</div>}
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="address_1"
							className="text-base text-gray-600 font-light"
						>
							Address 1
						</Label>
						<Input
							id="address_1"
							type="text"
							name="address_1"
							onChange={(e) => props.setData({ ...props.data, address_1: e.target.value })}
							value={props.data.address_1}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="address_2"
							className="text-base text-gray-600 font-light"
						>
							Address 2
						</Label>
						<Input
							id="address_2"
							type="text"
							name="address_2"
							onChange={(e) => props.setData({ ...props.data, address_2: e.target.value })}
							value={props.data.address_2}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="postcode"
							className="text-base text-gray-600 font-light"
						>
							Postcode
						</Label>
						<Input
							id="postcode"
							type="text"
							name="postcode"
							onChange={(e) => props.setData({ ...props.data, postcode: e.target.value })}
							value={props.data.postcode}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="state"
							className="text-base text-gray-600 font-light mt-5"
						>
							State
						</Label>
						<Select
							value={props.data.state}
							onValueChange={(value) => {
								//
								//@ts-ignore
								props.setData({ ...props.data, state: value })
								//
							}}
						>
							<SelectTrigger
								id="state"
								data-testid="state"
								className="w-full bg-gray-200 text-lg"
							>
								<SelectValue placeholder="Please select an Australian state or Other" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
								<SelectItem value="Other" className="text-lg">Other</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="celebrant_number"
							className="text-base text-gray-600 font-light"
						>
							Celebrant Number
						</Label>
						<Input
							id="celebrant_number"
							type="text"
							name="celebrant_number"
							onChange={(e) => props.setData({ ...props.data, celebrant_number: e.target.value })}
							value={props.data.celebrant_number}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Button
							className="w-full"
							onClick={() => props.setShowPasswordModal(true)}
						>
							Change Password
						</Button>
					</div>

				</CardContent>
			</Card>

			<Card className="col-span-2 md:col-span-1">
				<CardHeader>
					<CardTitle>Invoice</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="apply_qualification_to_invoice"
							className="text-base text-gray-600 font-light"
						>
							Show Qualification on the Invoice?
						</Label>
						<Select
							name="apply_qualification_to_invoice"
							value={props.data.apply_qualification_to_invoice}
							onValueChange={(value) => props.setData({ ...props.data, apply_qualification_to_invoice: value === "Yes" ? "Yes" : "No" })}
						>
							<SelectTrigger
								id="apply_qualification_to_invoice"
								data-testid="apply_qualification_to_invoice"
								className="w-full bg-gray-200 text-lg"
								name="apply_qualification_to_invoice"
							>
								<SelectValue placeholder="No" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								<SelectItem value="No" className="text-lg">No</SelectItem>
								<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="show_abn"
							className="text-base text-gray-600 font-light mt-5"
						>
							Show ABN on the Invoice?
						</Label>
						<Select
							name="show_abn"
							value={props.data.show_abn}
							onValueChange={(value) => props.setData({ ...props.data, show_abn: value === "Yes" ? "Yes" : "No" })}
						>
							<SelectTrigger
								id="show_abn"
								data-testid="show_abn"
								className="w-full bg-gray-200 text-lg"
								name="show_abn"
							>
								<SelectValue placeholder="No" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								<SelectItem value="No" className="text-lg">No</SelectItem>
								<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="abn"
							className="text-base text-gray-600 font-light"
						>
							ABN
						</Label>
						<Input
							id="abn"
							type="text"
							name="abn"
							onChange={(e) => props.setData({ ...props.data, abn: e.target.value })}
							value={props.data.abn}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="mobile"
							className="text-base text-gray-600 font-light"
						>
							Mobile
						</Label>
						<Input
							id="mobile"
							type="text"
							name="mobile"
							onChange={(e) => props.setData({ ...props.data, mobile: e.target.value })}
							value={props.data.mobile}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="website"
							className="text-base text-gray-600 font-light"
						>
							Website
						</Label>
						<Input
							id="website"
							type="text"
							name="website"
							onChange={(e) => props.setData({ ...props.data, website: e.target.value })}
							value={props.data.website}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="show_gst"
							className="text-base text-gray-600 font-light mt-5"
						>
							Show GST?
						</Label>
						<Select
							name="show_gst"
							value={props.data.show_gst}
							onValueChange={(value) => props.setData({ ...props.data, show_gst: value === "Yes" ? "Yes" : "No" })}
						>
							<SelectTrigger
								id="show_gst"
								data-testid="show_gst"
								className="w-full bg-gray-200 text-lg"
								name="show_gst"
							>
								<SelectValue placeholder="No" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								<SelectItem value="No" className="text-lg">No</SelectItem>
								<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="item_description"
							className="text-base text-gray-600 font-light"
						>
							Invoice Line Item Description
						</Label>
						<Input
							id="item_description"
							type="text"
							name="item_description"
							onChange={(e) => props.setData({ ...props.data, item_description: e.target.value })}
							value={props.data.item_description}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Label
							htmlFor="payment_terms"
							className="text-base text-gray-600 font-light"
						>
							Invoice Payment Terms
						</Label>
						<Input
							id="payment_terms"
							type="text"
							name="payment_terms"
							onChange={(e) => props.setData({ ...props.data, payment_terms: e.target.value })}
							value={props.data.payment_terms}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>

				</CardContent>
			</Card>

			<Card className="col-span-2 md:col-span-1">
				<CardHeader>
					<CardTitle>Invoice Lines</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="invoice_line_1"
							className="text-base text-gray-600 font-light"
						>
							Invoice Notes (Shown below the invoice)
						</Label>
						<Input
							id="invoice_line_1"
							type="text"
							name="invoice_line_1"
							onChange={(e) => props.setData({ ...props.data, invoice_line_1: e.target.value })}
							value={props.data.invoice_line_1}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Input
							id="invoice_line_2"
							type="text"
							name="invoice_line_2"
							onChange={(e) => props.setData({ ...props.data, invoice_line_2: e.target.value })}
							value={props.data.invoice_line_2}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Input
							id="invoice_line_3"
							type="text"
							name="invoice_line_3"
							onChange={(e) => props.setData({ ...props.data, invoice_line_3: e.target.value })}
							value={props.data.invoice_line_3}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Input
							id="invoice_line_4"
							type="text"
							name="invoice_line_4"
							onChange={(e) => props.setData({ ...props.data, invoice_line_4: e.target.value })}
							value={props.data.invoice_line_4}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Input
							id="invoice_line_5"
							type="text"
							name="invoice_line_5"
							onChange={(e) => props.setData({ ...props.data, invoice_line_5: e.target.value })}
							value={props.data.invoice_line_5}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Input
							id="invoice_line_6"
							type="text"
							name="invoice_line_6"
							onChange={(e) => props.setData({ ...props.data, invoice_line_6: e.target.value })}
							value={props.data.invoice_line_6}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Input
							id="invoice_line_7"
							type="text"
							name="invoice_line_7"
							onChange={(e) => props.setData({ ...props.data, invoice_line_7: e.target.value })}
							value={props.data.invoice_line_7}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-5">
						<Input
							id="invoice_line_8"
							type="text"
							name="invoice_line_8"
							onChange={(e) => props.setData({ ...props.data, invoice_line_8: e.target.value })}
							value={props.data.invoice_line_8}
							className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
						/>
					</div>

				</CardContent>
			</Card>
			<div className="col-span-2 my-4">
				<SaveButton />
			</div>
		</div>
	)
}

function PasswordModal(props: { setShowPasswordModal: any }) {

	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")
	const { toast } = useToast()

	async function submitData() {
		//
		const response = await ChangePasswordUpdate({ password: password })
		if (response.ok) {
			//
			toast({
				// title: "Settings Saved",
				duration: 1500,
				description: <div className=" h-auto w-full text-xl text-green-600 font-bold">Your password has been updated</div>,
			})
			//
			props.setShowPasswordModal(false)
			//
		} else {
			//
			toast({
				// title: "Settings Saved",
				duration: 1500,
				description: <div className=" h-auto w-full text-xl text-red-600 font-bold">Your password could not be updated</div>,
			})
			//
		}
		//
	}

	return (
		<Dialog
			defaultOpen={true}
			open={true}
			// onOpenChange={() => {
			// 	console.log("Trying to open/close")
			// }}
			modal={true}
		>
			<DialogContent className="">
				<DialogHeader>
					<DialogTitle>
						<div className="flex flex-row justify-between align-middle w-full h-10">
							<h2 className="text-3xl -mt-1">
								Change Password
							</h2>
							<Button
								className="-m-2 px-2 outline-none ring-0 ring-transparent ring-white border-0 text-gray-600"
								variant={"outline"}
								onClick={() => {
									props.setShowPasswordModal(false)
								}}
							>
								<X />
							</Button>
						</div>
					</DialogTitle>
				</DialogHeader>
				<div className="mt-3 font-light text-gray-600">
					Your new password must be at least 6 characters long
				</div>
				<div className="mt-3">
					<Label
						htmlFor="password"
					>
						Password
					</Label>
					<Input
						id="password"
						name="password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						autoFocus
					>
					</Input>
				</div>
				<div className="mt-3">
					<Label
						htmlFor="passwordConfirmation"
					>
						Password Confirmation
					</Label>
					<Input
						id="passwordConfirmation"
						name="passwordConfirmation"
						type="password"
						value={passwordConfirmation}
						onChange={(e) => setPasswordConfirmation(e.target.value)}
					>
					</Input>
				</div>
				<div className="h-8 mt-5">
					<DialogFooter className="h-full">
						<Button
							variant={"outline"}
							type="button"
							className="text-lg"
							onClick={() => {
								props.setShowPasswordModal(false)
							}}
						>
							Close
						</Button>
						<Button
							variant={"default"}
							type="submit"
							className="text-lg"
							onClick={submitData}
							disabled={!(password === passwordConfirmation && password.length >= 6)}
						>
							Change Password
						</Button>
					</DialogFooter>
				</div>
			</DialogContent>
		</Dialog>
	)
}

function BuyLicenseModal(props: { setShowBuyLicenseModal: any, data: any }) {

	const [errorMessage, setErrorMessage] = useState(null);
	const [topOrBottom, setTopOrBottom] = useState<"Top" | "Bottom">("Top")
	const [mode, setMode] = useState<"Pick" | "Pay">("Pick")
	const [paymentIntent, setPaymentIntent] = useState<any>(null)

	let title = ""
	if (props.data.society_confirmed === "Yes" && props.data.membership === "The Celebrant Society") {
		title = "The Celebrant Society Member Discount"
	} else if (props.data.celebrante_confirmed === "Yes" && props.data.membership === "Celebrante") {
		title = "The Celebrante Member Discount"
	}

	const License12Month = () => {
		return (
			<div className="grid gap-y-4 font-light text-gray-600 px-4 text-center justify-center mt-4">
				<div
					className={"flex max-w-md p-4 cursor-pointer items-center rounded-lg" + (topOrBottom === "Top" ? " bg-blue-200" : "")}
					onClick={() => setTopOrBottom("Top")}
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-briefcase-business text-right w-20 h-auto text-black"><path d="M12 12h.01" /><path d="M16 6V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2" /><path d="M22 13a18.15 18.15 0 0 1-20 0" /><rect width="20" height="14" x="2" y="6" rx="2" /></svg>
					<div className="flex flex-col w-full text-left ml-6">
						<div className="font-semibold underline text-black">
							12 Month License
						</div>
						<div className={title.length > 0 ? " line-through" : ""}>
							$99 (Inc GST)
						</div>
						<div className={title.length > 0 ? "" : " hidden"}>
							$77 (Inc GST)
						</div>
					</div>
				</div>
			</div>
		)
	}

	const License6Month = () => {
		return (
			<div className="flex font-light text-gray-600 px-4 text-center justify-center pb-6">
				<div
					className={"flex max-w-md p-4 cursor-pointer items-center rounded-lg" + (topOrBottom === "Bottom" ? " bg-blue-200" : "")}
					onClick={() => setTopOrBottom("Bottom")}
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-briefcase-business text-right w-20 h-auto text-black"><path d="M12 12h.01" /><path d="M16 6V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2" /><path d="M22 13a18.15 18.15 0 0 1-20 0" /><rect width="20" height="14" x="2" y="6" rx="2" /></svg>
					<div className="flex flex-col w-full text-left ml-6">
						<div className="font-semibold underline text-black">
							6 Month License
						</div>
						<div className={title.length > 0 ? " line-through" : ""}>
							$49.50 (Inc GST)
						</div>
						<div className={title.length > 0 ? "" : "hidden"}>
							$38.50 (Inc GST)
						</div>
					</div>
				</div>
			</div>
		)
	}

	async function createPaymentIntent(amountToPay: number) {
		//
		const response = await UserCreatePaymentIntent(amountToPay * 100)
		if (response.ok) {
			//
			const jsonBody = await response.json()
			//
			setPaymentIntent(jsonBody.client_secret)
			//
		}
	}

	let amount = 0
	if (title.length > 0 && topOrBottom === "Top") {
		amount = 77
	} else if (title.length > 0 && topOrBottom === "Bottom") {
		amount = 38.5
	} else if (title.length === 0 && topOrBottom === "Top") {
		amount = 99
	} else if (title.length === 0 && topOrBottom === "Bottom") {
		amount = 49.5
	}

	if (mode === "Pick" && paymentIntent !== null) {
		setPaymentIntent(null)
	}

	return (
		<Dialog
			defaultOpen={true}
			open={true}
			modal={true}
		>
			<DialogContent className="max-w-sm max-h-screen">
				<DialogHeader>
					<DialogTitle>
						<div className="flex flex-row justify-between align-middle w-full h-10">
							<h2 className="text-3xl -mt-1">
								License Payment
							</h2>
							<Button
								className="-m-2 px-2 outline-none ring-0 ring-transparent ring-white border-0 text-gray-600"
								variant={"outline"}
								onClick={() => {
									props.setShowBuyLicenseModal(false)
								}}
							>
								<X />
							</Button>
						</div>
					</DialogTitle>
				</DialogHeader>
				<div hidden={mode === "Pay"}>
					<div className="mt-6 sm:mt-2 text-center">
						{title}
					</div>
					<License12Month />
					<License6Month />
				</div>
				{mode === "Pay" && paymentIntent && <div>
					<div className="mt-6 sm:mt-2 mb-6 text-center">
						{title}
					</div>
					{topOrBottom === "Top" && <License12Month />}
					{topOrBottom === "Bottom" && <License6Month />}
					<div className="h-4"></div>
					<Elements stripe={stripePromise} options={{ clientSecret: paymentIntent }}>
						<CheckoutForm />
					</Elements>
				</div>}
				<div className="h-8 mt-5">
					<DialogFooter className="h-full">
						<Button
							variant={"outline"}
							type="button"
							className="text-lg"
							onClick={() => {
								props.setShowBuyLicenseModal(false)
							}}
						>
							Close
						</Button>
						<Button
							variant={"default"}
							type="submit"
							className={"text-lg " + (mode === "Pay" && paymentIntent ? "hidden" : "")}
							onClick={async () => {
								if (mode === "Pick") {
									createPaymentIntent(amount)
									setMode("Pay")
								}
							}}
						>
							Next
						</Button>
					</DialogFooter>
				</div>
			</DialogContent>
		</Dialog>
	)
}

function AFCCConfirmModal(props: { setShowAFCCModalResult: any }) {
	return (
		<Dialog
			defaultOpen={true}
			open={true}
			modal={true}
		>
			<DialogContent className="max-w-6xl max-h-screen">
				<DialogHeader>
					<DialogTitle>
						<div className="flex flex-row justify-between align-middle w-full h-10">
							<h2 className="text-3xl -mt-1">
								Claim to be an AFCC member
							</h2>
							<Button
								className="-m-2 px-2 outline-none ring-0 ring-transparent ring-white border-0 text-gray-600"
								variant={"outline"}
								onClick={() => {
									props.setShowAFCCModalResult(false)
								}}
							>
								<X />
							</Button>
						</div>
					</DialogTitle>
				</DialogHeader>
				<div className="grid gap-4">
					<div>
						<p>By saying yes, an email will be sent to the AFCC to confirm that you are an AFCC member.</p>
						<p>If the AFCC confirm that you are a member, they may grant you a license and you will not need to purchase a licence.</p>
					</div>
					<p>Are you sure you would like to claim that you are an AFCC member?</p>
					<p className="mt-4">You will also need to click the 'Save Settings' button to send the email.</p>
				</div>
				<div className="h-8 mt-5">
					<DialogFooter className="h-full">
						<Button
							variant={"outline"}
							type="button"
							className="text-lg"
							onClick={() => {
								props.setShowAFCCModalResult(false)
							}}
						>
							Cancel
						</Button>
						<Button
							variant={"default"}
							type="submit"
							className="text-lg"
							onClick={async () => {
								props.setShowAFCCModalResult(true)
							}}
						>
							Yes
						</Button>
					</DialogFooter>
				</div>
			</DialogContent>
		</Dialog>
	)
}

function CelebranteConfirmModal(props: { setShowCelebranteModalResult: any }) {
	return (
		<Dialog
			defaultOpen={true}
			open={true}
			modal={true}
		>
			<DialogContent className="max-w-6xl max-h-screen">
				<DialogHeader>
					<DialogTitle>
						<div className="flex flex-row justify-between align-middle w-full h-10">
							<h2 className="text-3xl -mt-1">
								Claim to be a Celebrante member
							</h2>
							<Button
								className="-m-2 px-2 outline-none ring-0 ring-transparent ring-white border-0 text-gray-600"
								variant={"outline"}
								onClick={() => {
									props.setShowCelebranteModalResult(false)
								}}
							>
								<X />
							</Button>
						</div>
					</DialogTitle>
				</DialogHeader>
				<div className="grid gap-4">
					<div>
						<p>By saying yes, an email will be sent to Celebrante to confirm that you are a Celebrante member.</p>
						<p>If Celebrante confirm that you are a member, you may be granted a discount.</p>
					</div>
					<p>Are you sure you would like to claim that you are a Celebrante member?</p>
					<p className="mt-4">You will also need to click the 'Save Settings' button to send the email.</p>
				</div>
				<div className="h-8 mt-5">
					<DialogFooter className="h-full">
						<Button
							variant={"outline"}
							type="button"
							className="text-lg"
							onClick={() => {
								props.setShowCelebranteModalResult(false)
							}}
						>
							Cancel
						</Button>
						<Button
							variant={"default"}
							type="submit"
							className="text-lg"
							onClick={async () => {
								props.setShowCelebranteModalResult(true)
							}}
						>
							Yes
						</Button>
					</DialogFooter>
				</div>
			</DialogContent>
		</Dialog>
	)
}

function SocietyConfirmModal(props: { setShowSocietyModalResult: any }) {
	return (
		<Dialog
			defaultOpen={true}
			open={true}
			modal={true}
		>
			<DialogContent className="max-w-6xl max-h-screen">
				<DialogHeader>
					<DialogTitle>
						<div className="flex flex-row justify-between align-middle w-full h-10">
							<h2 className="text-3xl -mt-1">
								Claim to be a Celebrant Society member
							</h2>
							<Button
								className="-m-2 px-2 outline-none ring-0 ring-transparent ring-white border-0 text-gray-600"
								variant={"outline"}
								onClick={() => {
									props.setShowSocietyModalResult(false)
								}}
							>
								<X />
							</Button>
						</div>
					</DialogTitle>
				</DialogHeader>
				<div className="grid gap-4">
					<div>
						<p>By saying yes, an email will be sent to The Celebrant Society to confirm that you are a Celebrant Society member.</p>
						<p>If The Celebrant Society confirm that you are a member, you may be granted a discount.</p>
					</div>
					<p>Are you sure you would like to claim that you are a Celebrant Society member?</p>
					<p className="mt-4">You will also need to click the 'Save Settings' button to send the email.</p>
				</div>
				<div className="h-8 mt-5">
					<DialogFooter className="h-full">
						<Button
							variant={"outline"}
							type="button"
							className="text-lg"
							onClick={() => {
								props.setShowSocietyModalResult(false)
							}}
						>
							Cancel
						</Button>
						<Button
							variant={"default"}
							type="submit"
							className="text-lg"
							onClick={async () => {
								props.setShowSocietyModalResult(true)
							}}
						>
							Yes
						</Button>
					</DialogFooter>
				</div>
			</DialogContent>
		</Dialog>
	)
}
