import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { LoginUser } from "../../lib/backend/auth";
import { setToken } from "../../lib/token";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button"
import { KeyRound, UserRoundPlus } from "lucide-react"

export default function LoginPage() {
	const [formDataEmail, setFormDataEmail] = useState("")
	const [formDataPassword, setFormDataPassword] = useState("")
	const [formDataError, setFormDataError] = useState(false)
	const [loginSuccess, setLoginSuccess] = useState(false)

	const navigate = useNavigate();

	useEffect(() => {
		if (loginSuccess) {
			navigate("/home/weddings")
		}
	}, [loginSuccess, navigate])

	async function formSubmit(event: any) {
		event.preventDefault()

		setFormDataError(false)

		const response = await LoginUser(
			formDataEmail,
			formDataPassword
		)
		if (response.ok) {
			const body = await response.json()
			setToken(body.token)
			setLoginSuccess(true)
		} else {
			setFormDataError(true)
		}
	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-24 w-auto"
						src="/logo.png"
						alt="Celebrant Briefcase"
					/>
					<h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
						Sign in to your account
					</h2>
				</div>

				<div hidden={!formDataError} className="mx-auto text-red-500 mt-12 text-xl">
					Invalid email or password
				</div>

				<div className="mt-14 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={formSubmit}>
						<div className="grid w-full max-w-sm items-center gap-1.5">
							<Label htmlFor="email">Email Address</Label>
							<Input
								id="email"
								type="email"
								placeholder="Email address"
								name="email"
								autoComplete="email"
								required
								onChange={(e) => setFormDataEmail(e.target.value)}
								value={formDataEmail}
								autoFocus
							/>
						</div>
						{/* <div>
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
								Email address
							</label>
							<div className="mt-2">
								<Input
									id="email"
									type="email"
									name="email"
									autoComplete="email"
									required
									onChange={(e) => setFormDataEmail(e.target.value)}
									value={formDataEmail}
								/>
							</div>
						</div> */}

						<div className="grid w-full max-w-sm items-center gap-1.5">
							<div className="flex items-center justify-between">
								<Label htmlFor="password">
									Password
								</Label>
								<div className="text-sm">
									<Link
										to="/forgot-password"
										className="font-semibold text-blue-600 hover:text-blue-500"
									>
										Forgot password?
									</Link>
								</div>
							</div>
							<Input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								required
								placeholder="Password"
								onChange={(e) => setFormDataPassword(e.target.value)}
								value={formDataPassword}
							/>
						</div>

						{/* <div>
							<div className="flex items-center justify-between">
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
									Password
								</label>
								<div className="text-sm">
									<Link to="/forgot-password" className="font-semibold text-indigo-600 hover:text-indigo-500">
										Forgot password?
									</Link>
								</div>
							</div>
							<div className="mt-2">
								<Input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									onChange={(e) => setFormDataPassword(e.target.value)}
									value={formDataPassword}
								/>
							</div>
						</div> */}

						<div>
							<Button
								type="submit"
								className="w-full"
							// className="w-full bg-blue-500 hover:bg-blue-600"
							>
								<KeyRound className="mr-2 h-4 w-4" /> Sign in
							</Button>
						</div>

						<div>
							<Link
								to="/register"
								type="submit"
								className="w-full"
							// variant={"secondary"}
							// className="w-full bg-green-500 hover:bg-green-600"
							>
								<Button
									type="button"
									variant={"secondary"}
									className="w-full"
								>
									<UserRoundPlus className="mr-2 h-4 w-4" /> Register New Account
								</Button>
							</Link>
						</div>
					</form>

				</div>
			</div>
		</>
	)
}
