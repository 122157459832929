import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";

export default function SettingsPage() {

	return (
		<div className="px-2 mt-4">
			<div>
				<div className="text-2xl font-semibold mb-4 flex justify-between">
					<h1 className="text-3xl font-bold">
						Support
					</h1>
				</div>
				<div className="grid grid-cols-1 max-w-4xl mt-10 text-gray-600">
					<h2 className="text-lg text-black font-bold">General</h2>
					<Accordion type="single" collapsible defaultValue="generalNoDefault" className="p-4">
						<AccordionItem value="general">
							<AccordionTrigger className="text-md">How does Celebrant Briefcase work?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">CB (Celebrant Briefcase) allows you to create and edit 'Weddings'.</p>
								<p className="mb-4">Weddings can be thought of, as groups of information about couples.</p>
								<p className="mb-4">The information in each wedding is used to print the forms.</p>
								<p className="">The great thing about this system, is that you only need to enter the data ONCE, to be able to print ALL the forms.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="general2">
							<AccordionTrigger className="text-md">Which operating systems does CB work on?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">CB has been carefully created and designed to support any browser, on any operating system, on any screen size.</p>
								<p className="">This includes Windows, Mac (OSX), Linux, iOS, Android, and many others.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="general3">
							<AccordionTrigger className="text-md">I use the Safari browser on a Mac (OSX/Macos) and I am having issues. HELP!</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">There have been a lot of fixes in Safari recently to help support the latest in web technologies.</p>
								<p className="mb-4">Please try updating to the latest version possible from the App Store.</p>
								<p className="">If you have issues, please contact me (Nick) in the feedback area. Alternatively, please try the Google Chrome web browser.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="general4">
							<AccordionTrigger className="text-md">Is the site secure?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">Absolutely! The site uses 256 bit encryption. This is the general standard of the web.</p>
								<p className="">You can always verify this on a web site you are on, by making sure you can see the lock icon in your browser (usually at the top left, but it may be on the top right depending on browser and OS).</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="general5">
							<AccordionTrigger className="text-md">Why is this a website, and not a Windows/MacOS/Android/iOS App?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">If CB was written as an application, you (the celebrant) would need to worry about licence keys, backups, ensuring you have the latest version, and how the data might persist accross multiple computers/devices.</p>
								<p className="mb-4">You would also not be able to manage your weddings on any mobile devices, or anywhere away from your computer.</p>
								<p className="mb-4">As a website, you don't need to worry about any of the above, and you can manage your weddings on any device.</p>
								<p className="">Also, I can push changes out to you faster, and I can manage and backup your data for you, saving you from these tasks.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="general6">
							<AccordionTrigger className="text-md">How do backups work?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">The database is backed up every hour for the last 24 hours, every day for the last 30 days, and once a month for the last year.</p>
								<p className="">Since weddings cannot be deleted, the backups have not been needed since the site went live, many years ago.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="general7">
							<AccordionTrigger className="text-md">Why can't I delete weddings?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">The ability to delete weddings brings a few potential issues.</p>
								<p className="mb-4">Firstly, you could accidentally delete a wedding.</p>
								<p className="mb-4">Secondly, if your account is compromised (by leaving a device logged in for example), the attacker would be able to cause some real damage.</p>
								<p className="">Instead of deleting weddings you dont want to see, you can 'Archive' a wedding, and then set the box above the Weddings to 'Hide Archived Weddings'. This option will hide any wedding marked as 'Archived'.</p>
							</AccordionContent>
						</AccordionItem>
					</Accordion>
					<h2 className="text-lg text-black font-bold">NOIM and Dec/Cert Combo</h2>
					<Accordion type="single" collapsible defaultValue="noimAndDecCertComboNoDefault" className="p-4">
						<AccordionItem value="noimAndDecCertCombo1">
							<AccordionTrigger className="text-md">Why is the text a different size in each field on the NOIM and dec/cert?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">The site once had a changeable font size for the NOIM and dec/cert that you (the celebrant) were able to change for each wedding.</p>
								<p className="mb-4">The issue with that system, is that some pieces of information can be so big, that they might not fit in the box, with the font size you have chosen! Having the data bleed out out of the box, and having the data be 'chopped off', are not very good solutions for this problem.</p>
								<p className="mb-4">So, to stop the issues of the text being too big for a box, and saving you from having to visually check your forms for this issue, CB was changed to use the 'Auto Size' feature of PDF files.</p>
								<p className="">Auto Size will automatically pick a font size for you, depending on the size of the text and how much room is available in the box on the form. This way, you can enter the data and print the forms, and not worry about issues of font size.</p>
							</AccordionContent>
						</AccordionItem>
					</Accordion>
					<h2 className="text-lg text-black font-bold">Form 15 and Printers</h2>
					<Accordion type="single" collapsible defaultValue="form15AndPrintersNoDefault" className="p-4">
						<AccordionItem value="form15AndPrinters1">
							<AccordionTrigger className="text-md">How do the Form 15 Printers work?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">Due to the fact that you can choose a font for your form 15, and depending on the maximum font height and width of each character (every font is different), CB can not guarantee that the data you have entered for the wedding will line up with your physical Form 15.</p>
								<p className="mb-4">So, a 'Printer' is a set of configuration data for your printer. Since you have more than one printer, you can have many printers on CB.</p>
								<p className="mb-4">The Printer on CB will remember the font family, font size, font colour, and, location of each element for your Form 15's.</p>
								<p className="mb-4">It is strongly recommended to print your Form 15 for each wedding on a blank piece of paper, then place it over the top of a real form 15 on a window and check to ensure the data lines up. If the data does not line up, you can go back to the printer settings and move any elements up, down, left, or right.</p>
								<p className="">Once you are sure the data will line up correctly, you can then print the form 15 onto the real template.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="form15AndPrinters2">
							<AccordionTrigger className="text-md">I have many printers setup. How can I tell CB which printer I want to use?</AccordionTrigger>
							<AccordionContent className="">
								<p className="">In each Printer there is the option to set it to the 'Current' printer. This option will force the current printer to be used on any Form 15 generation.</p>
							</AccordionContent>
						</AccordionItem>
					</Accordion>
					<h2 className="text-lg text-black font-bold">Invoicing</h2>
					<Accordion type="single" collapsible defaultValue="invoicingNoDefault" className="p-4">
						<AccordionItem value="invoicing1">
							<AccordionTrigger className="text-md">How do I print an invoice?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">In the 'Invoicing/Payments' tab of a wedding, click the 'NEW INVOICE' button.</p>
								<p className="mb-4">On creation of an invoice, you can not generate an invoice. You must 'SAVE' it to the server first.</p>
								<p className="mb-4">Once the invoice is saved, simply click the invoice, then click 'SHOW INVOICE'.</p>
								<p className="">If you make a change to an invoice, you must click 'SAVE' before you can generate a new invoice and see the change.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="invoicing2">
							<AccordionTrigger className="text-md">Why can't I change the details of my invoice?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">Once an invoice is marked as 'Sent' or 'Payment Received', it cannot be changed.</p>
								<p className="mb-4">This has been put in place to help ensure you dont make a change, and forget to send the 'changed' invoice to the couple.</p>
								<p className="">You can always change the invoice back to an 'Entered' status, make a change, then change the status back to 'Sent'.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="invoicing3">
							<AccordionTrigger className="text-md">Why is the 'Outstanding Amount' not taking my invoice amount into account?</AccordionTrigger>
							<AccordionContent className="">
								<p className="">Only invoices marked as 'Payment Received' are taken away from the 'Outstanding Amount'.</p>
							</AccordionContent>
						</AccordionItem>
						<AccordionItem value="invoicing4">
							<AccordionTrigger className="text-md">Where can I change the settings of the invoice?</AccordionTrigger>
							<AccordionContent className="">
								<p className="mb-4">The 'Settings' area of the site gives you the ability to:</p>
								<p className="mb-4">1: Enter your ABN</p>
								<p className="mb-4">2: Show/Hide your entered ABN</p>
								<p className="mb-4">3: Show/Hide GST</p>
								<p className="mb-4">4: Set the description of the line of the invoice</p>
								<p className="mb-4">5: Set the payment terms of the invoice (Default is '30 Days')</p>
								<p className="">6: Enter up to 8 lines of text which is shown below the invoice total (General info, payment details, etc...)</p>
							</AccordionContent>
						</AccordionItem>
					</Accordion>
				</div>
			</div>
		</div >
	)
}
