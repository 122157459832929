import React from 'react';
import { Link, useLocation } from 'react-router-dom'

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function SideBarLink({ id, link, text, icon, onClick }: { id: string | null, link: string, text: string, icon: any, onClick: any }) {
	const location = useLocation().pathname;
	const iconClass = classNames(
		location === link ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
		'h-6 w-6 shrink-0'
	)
	return (
		<Link
			id={id || ""}
			to={link}
			onClick={onClick}
			data-testid={text}
			className={classNames(
				location === link
					? 'bg-gray-800 text-white'
					: 'text-gray-400 hover:text-white hover:bg-gray-800',
				'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
			)}
		>
			{React.createElement(icon, { className: iconClass })}
			{text}
		</Link>
	)
}