import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { LogoutUser } from "../../lib/backend/auth";
import { setToken } from "../../lib/token";

export default function LogoutPage() {

	const [logoutSuccess, setLogoutSuccess] = useState(false)

	const navigate = useNavigate();

	useEffect(() => {
		if (logoutSuccess) {
			logoutUser()
		}
	}, [logoutSuccess, navigate])

	async function logoutUser() {
		await LogoutUser()
		setToken("")
		navigate("/")
	}

	if (logoutSuccess === false) {
		setLogoutSuccess(true)
	}

	return (
		<div className="m-8 text-2xl">Logging out...</div>
	)
}