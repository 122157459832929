import { Wedding } from "../../../backend/models/wedding";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "../components/ui/accordion"

import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../components/ui/card"

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../components/ui/select"

import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Link } from "react-router-dom";
import { Textarea } from "./ui/textarea";
import { DatePickerDemo } from "./ui/date-picker";
import { Button } from "./ui/button";
import { convertDateFieldToFieldDateFormat, dateToNiceString, removeLeadingZero } from "../lib/utils";
import { useEffect, useState } from "react";
import { Invoice } from "../../../backend/models/invoice";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "./ui/alert-dialog";
import { getToken } from "../lib/token";

const australian_states = [
	{ value: "Australian Capital Territory", label: "Australian Capital Territory" },
	{ value: "New South Wales", label: "New South Wales" },
	{ value: "Northern Territory", label: "Northern Territory" },
	{ value: "Queensland", label: "Queensland" },
	{ value: "South Australia", label: "South Australia" },
	{ value: "Tasmania", label: "Tasmania" },
	{ value: "Victoria", label: "Victoria" },
	{ value: "Western Australia", label: "Western Australia" },
]

const times = [
	{ value: "00:00:00", label: "00:00 AM" },
	{ value: "00:15:00", label: "00:15 AM" },
	{ value: "00:30:00", label: "00:30 AM" },
	{ value: "00:45:00", label: "00:45 AM" },
	{ value: "01:00:00", label: "01:00 AM" },
	{ value: "01:15:00", label: "01:15 AM" },
	{ value: "01:30:00", label: "01:30 AM" },
	{ value: "01:45:00", label: "01:45 AM" },
	{ value: "02:00:00", label: "02:00 AM" },
	{ value: "02:15:00", label: "02:15 AM" },
	{ value: "02:30:00", label: "02:30 AM" },
	{ value: "02:45:00", label: "02:45 AM" },
	{ value: "03:00:00", label: "03:00 AM" },
	{ value: "03:15:00", label: "03:15 AM" },
	{ value: "03:30:00", label: "03:30 AM" },
	{ value: "03:45:00", label: "03:45 AM" },
	{ value: "04:00:00", label: "04:00 AM" },
	{ value: "04:15:00", label: "04:15 AM" },
	{ value: "04:30:00", label: "04:30 AM" },
	{ value: "04:45:00", label: "04:45 AM" },
	{ value: "05:00:00", label: "05:00 AM" },
	{ value: "05:15:00", label: "05:15 AM" },
	{ value: "05:30:00", label: "05:30 AM" },
	{ value: "05:45:00", label: "05:45 AM" },
	{ value: "06:00:00", label: "06:00 AM" },
	{ value: "06:15:00", label: "06:15 AM" },
	{ value: "06:30:00", label: "06:30 AM" },
	{ value: "06:45:00", label: "06:45 AM" },
	{ value: "07:00:00", label: "07:00 AM" },
	{ value: "07:15:00", label: "07:15 AM" },
	{ value: "07:30:00", label: "07:30 AM" },
	{ value: "07:45:00", label: "07:45 AM" },
	{ value: "08:00:00", label: "08:00 AM" },
	{ value: "08:15:00", label: "08:15 AM" },
	{ value: "08:30:00", label: "08:30 AM" },
	{ value: "08:45:00", label: "08:45 AM" },
	{ value: "09:00:00", label: "09:00 AM" },
	{ value: "09:15:00", label: "09:15 AM" },
	{ value: "09:30:00", label: "09:30 AM" },
	{ value: "09:45:00", label: "09:45 AM" },
	{ value: "10:00:00", label: "10:00 AM" },
	{ value: "10:15:00", label: "10:15 AM" },
	{ value: "10:30:00", label: "10:30 AM" },
	{ value: "10:45:00", label: "10:45 AM" },
	{ value: "11:00:00", label: "11:00 AM" },
	{ value: "11:15:00", label: "11:15 AM" },
	{ value: "11:30:00", label: "11:30 AM" },
	{ value: "11:45:00", label: "11:45 AM" },
	{ value: "12:00:00", label: "12:00 PM" },
	{ value: "12:15:00", label: "12:15 PM" },
	{ value: "12:30:00", label: "12:30 PM" },
	{ value: "12:45:00", label: "12:45 PM" },
	{ value: "13:00:00", label: "01:00 PM" },
	{ value: "13:15:00", label: "01:15 PM" },
	{ value: "13:30:00", label: "01:30 PM" },
	{ value: "13:45:00", label: "01:45 PM" },
	{ value: "14:00:00", label: "02:00 PM" },
	{ value: "14:15:00", label: "02:15 PM" },
	{ value: "14:30:00", label: "02:30 PM" },
	{ value: "14:45:00", label: "02:45 PM" },
	{ value: "15:00:00", label: "03:00 PM" },
	{ value: "15:15:00", label: "03:15 PM" },
	{ value: "15:30:00", label: "03:30 PM" },
	{ value: "15:45:00", label: "03:45 PM" },
	{ value: "16:00:00", label: "04:00 PM" },
	{ value: "16:15:00", label: "04:15 PM" },
	{ value: "16:30:00", label: "04:30 PM" },
	{ value: "16:45:00", label: "04:45 PM" },
	{ value: "17:00:00", label: "05:00 PM" },
	{ value: "17:15:00", label: "05:15 PM" },
	{ value: "17:30:00", label: "05:30 PM" },
	{ value: "17:45:00", label: "05:45 PM" },
	{ value: "18:00:00", label: "06:00 PM" },
	{ value: "18:15:00", label: "06:15 PM" },
	{ value: "18:30:00", label: "06:30 PM" },
	{ value: "18:45:00", label: "06:45 PM" },
	{ value: "19:00:00", label: "07:00 PM" },
	{ value: "19:15:00", label: "07:15 PM" },
	{ value: "19:30:00", label: "07:30 PM" },
	{ value: "19:45:00", label: "07:45 PM" },
	{ value: "20:00:00", label: "08:00 PM" },
	{ value: "20:15:00", label: "08:15 PM" },
	{ value: "20:30:00", label: "08:30 PM" },
	{ value: "20:45:00", label: "08:45 PM" },
	{ value: "21:00:00", label: "09:00 PM" },
	{ value: "21:15:00", label: "09:15 PM" },
	{ value: "21:30:00", label: "09:30 PM" },
	{ value: "21:45:00", label: "09:45 PM" },
	{ value: "22:00:00", label: "10:00 PM" },
	{ value: "22:15:00", label: "10:15 PM" },
	{ value: "22:30:00", label: "10:30 PM" },
	{ value: "22:45:00", label: "10:45 PM" },
	{ value: "23:00:00", label: "11:00 PM" },
	{ value: "23:15:00", label: "11:15 PM" },
	{ value: "23:30:00", label: "11:30 PM" },
	{ value: "23:45:00", label: "11:45 PM" },
]


type props = {
	weddingData: Wedding & { invoices: Invoice[] },
	setWeddingData: Function,
	invoiceChanges: boolean[],
	setInvoiceChanges: Function,
	status: "I" | "A" | "T",
	mode?: "Public" | undefined
}

export function WeddingForm(props: props) {
	//
	// console.log(props.weddingData)
	// console.log(typeof props.weddingData.wedding_information_event_details_marriage_time)
	// console.log(props.invoiceChanges)
	const [showDeleteDialog, setShowDeleteDialog] = useState(false)
	const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState(0)
	const [token, setToken] = useState("")

	useEffect(() => {
		const token = getToken()
		setToken(token || "")
	}, [])

	function updateData(newValue: any) {
		if (props.status !== "I") {
			props.setWeddingData({ ...props.weddingData, ...newValue })
		}
	}

	let weddingUrl = ""
	if (props.weddingData.id !== 0) {
		const urlArray = window.location.href.split("/")
		weddingUrl = urlArray[0] + "//" + urlArray[2] + "/wedding/" + props.weddingData.id
	}

	if (props.mode && props.mode === "Public") {
		return (<div className="container max-w-5xl">

			<Card className="">
				{/* <CardHeader>
					
				</CardHeader> */}
				<CardContent className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<div className="grid w-full items-center gap-1.5 mt-4">
						<CardTitle className="underline">Event Details</CardTitle>
					</div>
					<div className="grid w-full items-center gap-1.5 mt-4">
						<Label
							htmlFor="wedding_information_event_details_marriage_time"
							className="text-base text-gray-600 font-light"
						>
							Marriage Time
						</Label>
						<Select
							value={props.weddingData.wedding_information_event_details_marriage_time ? props.weddingData.wedding_information_event_details_marriage_time.toString() : ""}
							onValueChange={(value) => updateData({ wedding_information_event_details_marriage_time: value })}
						>
							<SelectTrigger
								id="wedding_information_event_details_marriage_time"
								data-testid="wedding_information_event_details_marriage_time"
								className="w-full bg-gray-100 text-lg"
							>
								<SelectValue placeholder="Pick a Time" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								{times.map((timeRow, index) => <SelectItem key={index} value={timeRow.value} className="text-lg">{timeRow.label}</SelectItem>)}
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="wedding_information_event_details_marriage_date"
							className="text-base text-gray-600 font-light"
						>
							Wedding Date
						</Label>
						<input
							id="wedding_information_event_details_marriage_date"
							data-testid="wedding_information_event_details_marriage_date"
							type="date"
							value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_event_details_marriage_date)}
							onChange={(e) => updateData({ wedding_information_event_details_marriage_date: e.target.value })}
							className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="wedding_information_event_details_marriage_location"
							className="text-base text-gray-600 font-light"
						>
							Marriage Location
						</Label>
						<Input
							id="wedding_information_event_details_marriage_location"
							type="text"
							name="wedding_information_event_details_marriage_location"
							onChange={(e) => updateData({ wedding_information_event_details_marriage_location: e.target.value })}
							value={props.weddingData.wedding_information_event_details_marriage_location}
							className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="wedding_information_event_details_marriage_city"
							className="text-base text-gray-600 font-light"
						>
							City, town or suburb of the place of ceremony
						</Label>
						<Input
							id="wedding_information_event_details_marriage_city"
							type="text"
							name="wedding_information_event_details_marriage_city"
							onChange={(e) => updateData({ wedding_information_event_details_marriage_city: e.target.value })}
							value={props.weddingData.wedding_information_event_details_marriage_city}
							className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="wedding_information_event_details_marriage_postcode"
							className="text-base text-gray-600 font-light"
						>
							Postcode
						</Label>
						<Input
							id="wedding_information_event_details_marriage_postcode"
							type="text"
							name="wedding_information_event_details_marriage_postcode"
							onChange={(e) => updateData({ wedding_information_event_details_marriage_postcode: e.target.value })}
							value={props.weddingData.wedding_information_event_details_marriage_postcode}
							className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
						/>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="wedding_information_event_details_marriage_state_australia"
							className="text-base text-gray-600 font-light"
						>
							Marriage State (Australia)
						</Label>
						<Select
							value={props.weddingData.wedding_information_event_details_marriage_state_australia}
							onValueChange={(value) => {
								//
								const newOtherValue = value !== "Other" ? "" : props.weddingData.wedding_information_event_details_marriage_state_other
								//
								updateData({
									...props.weddingData,
									wedding_information_event_details_marriage_state_australia: value,
									wedding_information_event_details_marriage_state_other: newOtherValue
								})
								//
							}}
						>
							<SelectTrigger
								id="wedding_information_event_details_marriage_state_australia"
								data-testid="wedding_information_event_details_marriage_state_australia"
								className="w-full bg-gray-100 text-lg"
							>
								<SelectValue placeholder="Please select an Australian state or Other" />
							</SelectTrigger>
							<SelectContent className="text-lg">
								{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
								<SelectItem value="Other" className="text-lg">Other</SelectItem>
							</SelectContent>
						</Select>
					</div>
					<div className="grid w-full items-center gap-1.5">
						<Label
							htmlFor="wedding_information_event_details_marriage_state_other"
							className="text-base text-gray-600 font-light"
						>
							Marriage State (Other)
						</Label>
						<Input
							id="wedding_information_event_details_marriage_state_other"
							type="text"
							name="wedding_information_event_details_marriage_state_other"
							disabled={props.weddingData.wedding_information_event_details_marriage_state_australia !== "Other"}
							onChange={(e) => updateData({ wedding_information_event_details_marriage_state_other: e.target.value })}
							value={props.weddingData.wedding_information_event_details_marriage_state_other}
							className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
						/>
					</div>
				</CardContent>
			</Card>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">

				<Card className="col-span-2 md:col-span-1 mb-8">
					<CardHeader>
						<CardTitle className="underline text-center">Party 1</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_description"
								className="text-base text-gray-600 font-light mt-5"
							>
								Description
							</Label>
							<Select
								name="party12_person_1_description"
								value={props.weddingData.party12_person_1_description}
								onValueChange={(value) => updateData({ party12_person_1_description: value })}
							>
								<SelectTrigger
									id="party12_person_1_description"
									data-testid="party12_person_1_description"
									className="w-full bg-gray-100 text-lg"
									name="party12_person_1_description"
								>
									<SelectValue placeholder="No" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="Partner" className="text-lg">Partner</SelectItem>
									<SelectItem value="Bride" className="text-lg">Bride</SelectItem>
									<SelectItem value="Groom" className="text-lg">Groom</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_surname"
								className="text-base text-gray-600 font-light mt-5"
							>
								Surname
							</Label>
							<Input
								id="party12_person_1_surname"
								type="text"
								name="party12_person_1_surname"
								onChange={(e) => updateData({ party12_person_1_surname: e.target.value })}
								value={props.weddingData.party12_person_1_surname}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_sex"
								className="text-base text-gray-600 font-light mt-5"
							>
								Sex
							</Label>
							<Select
								name="party12_person_1_sex"
								value={props.weddingData.party12_person_1_sex}
								onValueChange={(value) => updateData({ party12_person_1_sex: value })}
							>
								<SelectTrigger
									id="party12_person_1_sex"
									data-testid="party12_person_1_sex"
									className="w-full bg-gray-100 text-lg"
									name="party12_person_1_sex"
								>
									<SelectValue placeholder="No" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="Non-Binary" className="text-lg">Non-Binary</SelectItem>
									<SelectItem value="Female" className="text-lg">Female</SelectItem>
									<SelectItem value="Male" className="text-lg">Male</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_first_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								First Name and Other
							</Label>
							<Input
								id="party12_person_1_first_name"
								type="text"
								name="party12_person_1_first_name"
								onChange={(e) => updateData({ party12_person_1_first_name: e.target.value })}
								value={props.weddingData.party12_person_1_first_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_occupation"
								className="text-base text-gray-600 font-light mt-5"
							>
								Occupation
							</Label>
							<Input
								id="party12_person_1_occupation"
								type="text"
								name="party12_person_1_occupation"
								onChange={(e) => updateData({ party12_person_1_occupation: e.target.value })}
								value={props.weddingData.party12_person_1_occupation}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<Button
							variant={"default"}
							className="mt-4 w-full text-lg font-light"
							disabled={props.status === "I"}
							onClick={() => {
								//
								updateData({
									...props.weddingData,
									party12_person_1_address_1: props.weddingData.party12_person_2_address_1,
									party12_person_1_address_2: props.weddingData.party12_person_2_address_2,
									party12_person_1_city: props.weddingData.party12_person_2_city,
									party12_person_1_postcode: props.weddingData.party12_person_2_postcode,
									party12_person_1_state: props.weddingData.party12_person_2_state,
									party12_person_1_state_other: props.weddingData.party12_person_2_state_other,
								})
								//
							}}
						>
							Copy Address from party 2
						</Button>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_address_1"
								className="text-base text-gray-600 font-light mt-5"
							>
								Address (1)
							</Label>
							<Input
								id="party12_person_1_address_1"
								type="text"
								name="party12_person_1_address_1"
								onChange={(e) => updateData({ party12_person_1_address_1: e.target.value })}
								value={props.weddingData.party12_person_1_address_1}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_address_2"
								className="text-base text-gray-600 font-light mt-5"
							>
								Address (2)
							</Label>
							<Input
								id="party12_person_1_address_2"
								type="text"
								name="party12_person_1_address_2"
								onChange={(e) => updateData({ party12_person_1_address_2: e.target.value })}
								value={props.weddingData.party12_person_1_address_2}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_city"
								className="text-base text-gray-600 font-light mt-5"
							>
								City
							</Label>
							<Input
								id="party12_person_1_city"
								type="text"
								name="party12_person_1_city"
								onChange={(e) => updateData({ party12_person_1_city: e.target.value })}
								value={props.weddingData.party12_person_1_city}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_postcode"
								className="text-base text-gray-600 font-light mt-5"
							>
								Postcode
							</Label>
							<Input
								id="party12_person_1_postcode"
								type="text"
								name="party12_person_1_postcode"
								onChange={(e) => updateData({ party12_person_1_postcode: e.target.value })}
								value={props.weddingData.party12_person_1_postcode}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_state"
								className="text-base text-gray-600 font-light mt-5"
							>
								State
							</Label>
							<Select
								value={props.weddingData.party12_person_1_state}
								onValueChange={(value) => {
									//
									const newOtherValue = value !== "Other" ? "" : props.weddingData.party12_person_1_state_other
									//
									updateData({
										...props.weddingData,
										party12_person_1_state: value,
										party12_person_1_state_other: newOtherValue
									})
									//
								}}
							>
								<SelectTrigger
									id="party12_person_1_state"
									data-testid="party12_person_1_state"
									className="w-full bg-gray-100 text-lg"
								>
									<SelectValue placeholder="Please select an Australian state or Other" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
									<SelectItem value="Other" className="text-lg">Other</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_state_other"
								className="text-base text-gray-600 font-light mt-5"
							>
								State (Other)
							</Label>
							<Input
								id="party12_person_1_state_other"
								type="text"
								name="party12_person_1_state_other"
								disabled={props.weddingData.party12_person_1_state !== "Other"}
								onChange={(e) => updateData({ party12_person_1_state_other: e.target.value })}
								value={props.weddingData.party12_person_1_state_other}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_town_of_birth"
								className="text-base text-gray-600 font-light mt-5"
							>
								City/Town of Birth
							</Label>
							<Input
								id="party12_person_1_town_of_birth"
								type="text"
								name="party12_person_1_town_of_birth"
								onChange={(e) => updateData({ party12_person_1_town_of_birth: e.target.value })}
								value={props.weddingData.party12_person_1_town_of_birth}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_birthplace_state"
								className="text-base text-gray-600 font-light mt-5"
							>
								Birthplace State (Australia)
							</Label>
							<Select
								value={props.weddingData.party12_person_1_birthplace_state}
								onValueChange={(value) => {
									//
									const newOtherValue = value !== "Other" ? "" : props.weddingData.party12_person_1_birthplace_state_other
									//
									updateData({
										...props.weddingData,
										party12_person_1_birthplace_state: value,
										party12_person_1_birthplace_state_other: newOtherValue
									})
									//
								}}
							>
								<SelectTrigger
									id="party12_person_1_birthplace_state"
									data-testid="party12_person_1_birthplace_state"
									className="w-full bg-gray-100 text-lg"
								>
									<SelectValue placeholder="Please select an Australian state or Other" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
									<SelectItem value="Other" className="text-lg">Other</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_birthplace_state_other"
								className="text-base text-gray-600 font-light mt-5"
							>
								Country of Birth (If not Australia)
							</Label>
							<Input
								id="party12_person_1_birthplace_state_other"
								type="text"
								name="party12_person_1_birthplace_state_other"
								disabled={props.weddingData.party12_person_1_birthplace_state !== "Other"}
								onChange={(e) => updateData({ party12_person_1_birthplace_state_other: e.target.value })}
								value={props.weddingData.party12_person_1_birthplace_state_other}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_conjugal"
								className="text-base text-gray-600 font-light mt-5"
							>
								Conjugal
							</Label>
							<Select
								value={props.weddingData.party12_person_1_conjugal}
								onValueChange={(value) => {
									//
									updateData({
										...props.weddingData,
										party12_person_1_conjugal: value
									})
									//
								}}
							>
								<SelectTrigger
									id="party12_person_1_conjugal"
									data-testid="party12_person_1_conjugal"
									className="w-full bg-gray-100 text-lg"
								>
									<SelectValue placeholder="Please select a conjugal status" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="Never validly married" className="text-lg">Never validly married</SelectItem>
									<SelectItem value="Widowed" className="text-lg">Widowed</SelectItem>
									<SelectItem value="Divorced" className="text-lg">Divorced</SelectItem>
									<SelectItem value="Divorce pending" className="text-lg">Divorce pending</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_dob"
								className="text-base text-gray-600 font-light mt-5"
							>
								Date of Birth
							</Label>
							<Input
								id="party12_person_1_dob"
								type="date"
								value={convertDateFieldToFieldDateFormat(props.weddingData.party12_person_1_dob)}
								onChange={(e) => updateData({ party12_person_1_dob: e.target.value })}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_parent_1_full_current_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 1 - Full Current Name
							</Label>
							<Input
								id="party12_person_1_parent_1_full_current_name"
								type="text"
								name="party12_person_1_parent_1_full_current_name"
								onChange={(e) => updateData({ party12_person_1_parent_1_full_current_name: e.target.value })}
								value={props.weddingData.party12_person_1_parent_1_full_current_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_parent_1_full_birth_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 1 - Full Birth Name
							</Label>
							<Input
								id="party12_person_1_parent_1_full_birth_name"
								type="text"
								name="party12_person_1_parent_1_full_birth_name"
								onChange={(e) => updateData({ party12_person_1_parent_1_full_birth_name: e.target.value })}
								value={props.weddingData.party12_person_1_parent_1_full_birth_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_parent_2_full_current_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 2 - Full Current Name
							</Label>
							<Input
								id="party12_person_1_parent_2_full_current_name"
								type="text"
								name="party12_person_1_parent_2_full_current_name"
								onChange={(e) => updateData({ party12_person_1_parent_2_full_current_name: e.target.value })}
								value={props.weddingData.party12_person_1_parent_2_full_current_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_parent_2_full_birth_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 2 - Full Birth Name
							</Label>
							<Input
								id="party12_person_1_parent_2_full_birth_name"
								type="text"
								name="party12_person_1_parent_2_full_birth_name"
								onChange={(e) => updateData({ party12_person_1_parent_2_full_birth_name: e.target.value })}
								value={props.weddingData.party12_person_1_parent_2_full_birth_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_parent_1_country_of_birth"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 1 - Country of Birth
							</Label>
							<Input
								id="party12_person_1_parent_1_country_of_birth"
								type="text"
								name="party12_person_1_parent_1_country_of_birth"
								onChange={(e) => updateData({ party12_person_1_parent_1_country_of_birth: e.target.value })}
								value={props.weddingData.party12_person_1_parent_1_country_of_birth}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_1_parent_2_country_of_birth"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 2 - Country of Birth
							</Label>
							<Input
								id="party12_person_1_parent_2_country_of_birth"
								type="text"
								name="party12_person_1_parent_2_country_of_birth"
								onChange={(e) => updateData({ party12_person_1_parent_2_country_of_birth: e.target.value })}
								value={props.weddingData.party12_person_1_parent_2_country_of_birth}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>

					</CardContent>
				</Card>

				<Card className="col-span-2 md:col-span-1 mb-8">
					<CardHeader>
						<CardTitle className="underline text-center">Party 2</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_description"
								className="text-base text-gray-600 font-light mt-5"
							>
								Description
							</Label>
							<Select
								name="party12_person_2_description"
								value={props.weddingData.party12_person_2_description}
								onValueChange={(value) => updateData({ party12_person_2_description: value })}
							>
								<SelectTrigger
									id="party12_person_2_description"
									data-testid="party12_person_2_description"
									className="w-full bg-gray-100 text-lg"
									name="party12_person_2_description"
								>
									<SelectValue placeholder="No" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="Partner" className="text-lg">Partner</SelectItem>
									<SelectItem value="Bride" className="text-lg">Bride</SelectItem>
									<SelectItem value="Groom" className="text-lg">Groom</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_surname"
								className="text-base text-gray-600 font-light mt-5"
							>
								Surname
							</Label>
							<Input
								id="party12_person_2_surname"
								type="text"
								name="party12_person_2_surname"
								onChange={(e) => updateData({ party12_person_2_surname: e.target.value })}
								value={props.weddingData.party12_person_2_surname}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_sex"
								className="text-base text-gray-600 font-light mt-5"
							>
								Sex
							</Label>
							<Select
								name="party12_person_2_sex"
								value={props.weddingData.party12_person_2_sex}
								onValueChange={(value) => updateData({ party12_person_2_sex: value })}
							>
								<SelectTrigger
									id="party12_person_2_sex"
									data-testid="party12_person_2_sex"
									className="w-full bg-gray-100 text-lg"
									name="party12_person_2_sex"
								>
									<SelectValue placeholder="No" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="Non-Binary" className="text-lg">Non-Binary</SelectItem>
									<SelectItem value="Female" className="text-lg">Female</SelectItem>
									<SelectItem value="Male" className="text-lg">Male</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_first_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								First Name and Other
							</Label>
							<Input
								id="party12_person_2_first_name"
								type="text"
								name="party12_person_2_first_name"
								onChange={(e) => updateData({ party12_person_2_first_name: e.target.value })}
								value={props.weddingData.party12_person_2_first_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_occupation"
								className="text-base text-gray-600 font-light mt-5"
							>
								Occupation
							</Label>
							<Input
								id="party12_person_2_occupation"
								type="text"
								name="party12_person_2_occupation"
								onChange={(e) => updateData({ party12_person_2_occupation: e.target.value })}
								value={props.weddingData.party12_person_2_occupation}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<Button
							variant={"default"}
							className="mt-4 w-full text-lg font-light"
							disabled={props.status === "I"}
							onClick={() => {
								//
								updateData({
									...props.weddingData,
									party12_person_2_address_1: props.weddingData.party12_person_1_address_1,
									party12_person_2_address_2: props.weddingData.party12_person_1_address_2,
									party12_person_2_city: props.weddingData.party12_person_1_city,
									party12_person_2_postcode: props.weddingData.party12_person_1_postcode,
									party12_person_2_state: props.weddingData.party12_person_1_state,
									party12_person_2_state_other: props.weddingData.party12_person_1_state_other,
								})
								//
							}}
						>
							Copy Address from party 1
						</Button>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_address_1"
								className="text-base text-gray-600 font-light mt-5"
							>
								Address (1)
							</Label>
							<Input
								id="party12_person_2_address_1"
								type="text"
								name="party12_person_2_address_1"
								onChange={(e) => updateData({ party12_person_2_address_1: e.target.value })}
								value={props.weddingData.party12_person_2_address_1}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_address_2"
								className="text-base text-gray-600 font-light mt-5"
							>
								Address (2)
							</Label>
							<Input
								id="party12_person_2_address_2"
								type="text"
								name="party12_person_2_address_2"
								onChange={(e) => updateData({ party12_person_2_address_2: e.target.value })}
								value={props.weddingData.party12_person_2_address_2}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_city"
								className="text-base text-gray-600 font-light mt-5"
							>
								City
							</Label>
							<Input
								id="party12_person_2_city"
								type="text"
								name="party12_person_2_city"
								onChange={(e) => updateData({ party12_person_2_city: e.target.value })}
								value={props.weddingData.party12_person_2_city}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_postcode"
								className="text-base text-gray-600 font-light mt-5"
							>
								Postcode
							</Label>
							<Input
								id="party12_person_2_postcode"
								type="text"
								name="party12_person_2_postcode"
								onChange={(e) => updateData({ party12_person_2_postcode: e.target.value })}
								value={props.weddingData.party12_person_2_postcode}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_state"
								className="text-base text-gray-600 font-light mt-5"
							>
								State
							</Label>
							<Select
								value={props.weddingData.party12_person_2_state}
								onValueChange={(value) => {
									//
									const newOtherValue = value !== "Other" ? "" : props.weddingData.party12_person_2_state_other
									//
									updateData({
										...props.weddingData,
										party12_person_2_state: value,
										party12_person_2_state_other: newOtherValue
									})
									//
								}}
							>
								<SelectTrigger
									id="party12_person_2_state"
									data-testid="party12_person_2_state"
									className="w-full bg-gray-100 text-lg"
								>
									<SelectValue placeholder="Please select an Australian state or Other" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
									<SelectItem value="Other" className="text-lg">Other</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_state_other"
								className="text-base text-gray-600 font-light mt-5"
							>
								State (Other)
							</Label>
							<Input
								id="party12_person_2_state_other"
								type="text"
								name="party12_person_2_state_other"
								disabled={props.weddingData.party12_person_2_state !== "Other"}
								onChange={(e) => updateData({ party12_person_2_state_other: e.target.value })}
								value={props.weddingData.party12_person_2_state_other}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_town_of_birth"
								className="text-base text-gray-600 font-light mt-5"
							>
								City/Town of Birth
							</Label>
							<Input
								id="party12_person_2_town_of_birth"
								type="text"
								name="party12_person_2_town_of_birth"
								onChange={(e) => updateData({ party12_person_2_town_of_birth: e.target.value })}
								value={props.weddingData.party12_person_2_town_of_birth}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_birthplace_state"
								className="text-base text-gray-600 font-light mt-5"
							>
								Birthplace State (Australia)
							</Label>
							<Select
								value={props.weddingData.party12_person_2_birthplace_state}
								onValueChange={(value) => {
									//
									const newOtherValue = value !== "Other" ? "" : props.weddingData.party12_person_2_birthplace_state_other
									//
									updateData({
										...props.weddingData,
										party12_person_2_birthplace_state: value,
										party12_person_2_birthplace_state_other: newOtherValue
									})
									//
								}}
							>
								<SelectTrigger
									id="party12_person_2_birthplace_state"
									data-testid="party12_person_2_birthplace_state"
									className="w-full bg-gray-100 text-lg"
								>
									<SelectValue placeholder="Please select an Australian state or Other" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
									<SelectItem value="Other" className="text-lg">Other</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_birthplace_state_other"
								className="text-base text-gray-600 font-light mt-5"
							>
								Country of Birth (If not Australia)
							</Label>
							<Input
								id="party12_person_2_birthplace_state_other"
								type="text"
								name="party12_person_2_birthplace_state_other"
								disabled={props.weddingData.party12_person_2_birthplace_state !== "Other"}
								onChange={(e) => updateData({ party12_person_2_birthplace_state_other: e.target.value })}
								value={props.weddingData.party12_person_2_birthplace_state_other}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_conjugal"
								className="text-base text-gray-600 font-light mt-5"
							>
								Conjugal
							</Label>
							<Select
								value={props.weddingData.party12_person_2_conjugal}
								onValueChange={(value) => {
									//
									updateData({
										...props.weddingData,
										party12_person_2_conjugal: value
									})
									//
								}}
							>
								<SelectTrigger
									id="party12_person_2_conjugal"
									data-testid="party12_person_2_conjugal"
									className="w-full bg-gray-100 text-lg"
								>
									<SelectValue placeholder="Please select a conjugal status" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="Never validly married" className="text-lg">Never validly married</SelectItem>
									<SelectItem value="Widowed" className="text-lg">Widowed</SelectItem>
									<SelectItem value="Divorced" className="text-lg">Divorced</SelectItem>
									<SelectItem value="Divorce pending" className="text-lg">Divorce pending</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_dob"
								className="text-base text-gray-600 font-light mt-5"
							>
								Date of Birth
							</Label>
							<Input
								id="party12_person_2_dob"
								type="date"
								value={convertDateFieldToFieldDateFormat(props.weddingData.party12_person_2_dob)}
								onChange={(e) => updateData({ party12_person_2_dob: e.target.value })}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_parent_1_full_current_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 1 - Full Current Name
							</Label>
							<Input
								id="party12_person_2_parent_1_full_current_name"
								type="text"
								name="party12_person_2_parent_1_full_current_name"
								onChange={(e) => updateData({ party12_person_2_parent_1_full_current_name: e.target.value })}
								value={props.weddingData.party12_person_2_parent_1_full_current_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_parent_1_full_birth_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 1 - Full Birth Name
							</Label>
							<Input
								id="party12_person_2_parent_1_full_birth_name"
								type="text"
								name="party12_person_2_parent_1_full_birth_name"
								onChange={(e) => updateData({ party12_person_2_parent_1_full_birth_name: e.target.value })}
								value={props.weddingData.party12_person_2_parent_1_full_birth_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_parent_2_full_current_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 2 - Full Current Name
							</Label>
							<Input
								id="party12_person_2_parent_2_full_current_name"
								type="text"
								name="party12_person_2_parent_2_full_current_name"
								onChange={(e) => updateData({ party12_person_2_parent_2_full_current_name: e.target.value })}
								value={props.weddingData.party12_person_2_parent_2_full_current_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_parent_2_full_birth_name"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 2 - Full Birth Name
							</Label>
							<Input
								id="party12_person_2_parent_2_full_birth_name"
								type="text"
								name="party12_person_2_parent_2_full_birth_name"
								onChange={(e) => updateData({ party12_person_2_parent_2_full_birth_name: e.target.value })}
								value={props.weddingData.party12_person_2_parent_2_full_birth_name}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_parent_1_country_of_birth"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 1 - Country of Birth
							</Label>
							<Input
								id="party12_person_2_parent_1_country_of_birth"
								type="text"
								name="party12_person_2_parent_1_country_of_birth"
								onChange={(e) => updateData({ party12_person_2_parent_1_country_of_birth: e.target.value })}
								value={props.weddingData.party12_person_2_parent_1_country_of_birth}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="party12_person_2_parent_2_country_of_birth"
								className="text-base text-gray-600 font-light mt-5"
							>
								Parent 2 - Country of Birth
							</Label>
							<Input
								id="party12_person_2_parent_2_country_of_birth"
								type="text"
								name="party12_person_2_parent_2_country_of_birth"
								onChange={(e) => updateData({ party12_person_2_parent_2_country_of_birth: e.target.value })}
								value={props.weddingData.party12_person_2_parent_2_country_of_birth}
								className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
							/>
						</div>

					</CardContent>
				</Card>


			</div>

		</div>)
	} else {
		//
		return (
			<Accordion type="single" collapsible defaultValue="general" className="p-4">
				<AccordionItem value="general">
					<AccordionTrigger className="text-lg">General</AccordionTrigger>
					<AccordionContent className="grid grid-cols-2 gap-4">
						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">General</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="general_wedding_name"
										className="text-base text-gray-600 font-light"
									>
										Wedding Name
									</Label>
									<Input
										id="general_wedding_name"
										type="text"
										name="general_wedding_name"
										onChange={(e) => updateData({ general_wedding_name: e.target.value })}
										value={props.weddingData.general_wedding_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
										autoFocus
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="general_archive_wedding"
										className="text-base text-gray-600 font-light mt-5"
									>
										Archive Wedding?
									</Label>
									<Select
										name="general_archive_wedding"
										value={props.weddingData.general_archive_wedding}
										onValueChange={(value) => updateData({ general_archive_wedding: value })}
									>
										<SelectTrigger
											id="general_archive_wedding"
											data-testid="general_archive_wedding"
											className="w-full bg-gray-100 text-lg"
											name="general_archive_wedding"
										>
											<SelectValue placeholder="No" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="general_form15_cert_number"
										className="text-base text-gray-600 font-light mt-5"
									>
										Form 15 Number(s)
									</Label>
									<Input
										id="general_form15_cert_number"
										type="text"
										name="general_form15_cert_number"
										onChange={(e) => updateData({ general_form15_cert_number: e.target.value })}
										value={props.weddingData.general_form15_cert_number}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
							</CardContent>
						</Card>
						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Forms</CardTitle>
							</CardHeader>
							<CardContent>
								<div
									className="w-full mt-5"
									hidden={props.weddingData.id === 0}
								>
									<Link
										to={"http://localhost:3333/forms/noim/" + props.weddingData.id + "?token=" + token}
										target="_target"
										className="flex w-full text-lg text-blue-700 bg-gray-100 rounded-lg p-2 justify-center hover:underline"
									>
										NOIM
									</Link>
								</div>
								<div
									className="w-full mt-5"
									hidden={props.weddingData.id === 0}
								>
									<Link
										to={"http://localhost:3333/forms/dec-cert/" + props.weddingData.id + "?token=" + token}
										target="_target"
										className="flex w-full text-lg text-blue-700 bg-gray-100 rounded-lg p-2 justify-center hover:underline"
									>
										Dec/Cert Combo
									</Link>
								</div>
								<div
									className="w-full mt-5"
									hidden={props.weddingData.id === 0}
								>
									<Link
										to={"http://localhost:3333/forms/certificate/" + props.weddingData.id + "?token=" + token}
										target="_target"
										className="flex w-full text-lg text-blue-700 bg-gray-100 rounded-lg p-2 justify-center hover:underline"
									>
										Form 15
									</Link>
								</div>
							</CardContent>
						</Card>
						<Card className="col-span-2">
							<CardHeader>
								<CardTitle className="underline text-center">Public Link</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="text-base text-gray-600 font-light">
									This link gives the public access to enter data into this wedding.
								</div>

								<div className="text-base text-gray-600 font-light mt-5">
									It is intended to optionally send to the couple before your initial meeting with them.
								</div>

								<div className="text-base text-gray-600 font-light mt-5">
									When the link is locked, the public will no longer be able to change the wedding.
								</div>

								<div className="text-base text-gray-600 font-light mt-5">
									This should be treated with care and would normally only be unlocked for the initial data entry by your couple.
								</div>

								<div className="text-base text-gray-600 font-light mt-5">
									Public Link:
								</div>

								<Link
									to={weddingUrl}
									target="_target"
									className="text-base text-blue-700 font-light hover:underline overflow-hidden mx-2 ml-0"
								>
									{weddingUrl}
								</Link>

								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="general_public_link_locked"
										className="text-base text-gray-600 font-light mt-5"
									>
										Wedding Locked?
									</Label>
									<Select
										value={props.weddingData.general_public_link_locked}
										onValueChange={(value) => updateData({ general_public_link_locked: value })}
									>
										<SelectTrigger
											id="general_public_link_locked"
											data-testid="general_public_link_locked"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Yes" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
											<SelectItem value="No" className="text-lg">No</SelectItem>
										</SelectContent>
									</Select>
								</div>

							</CardContent>
						</Card>
						<Card className="col-span-2">
							<CardHeader>
								<CardTitle className="underline text-center">Wedding Notes</CardTitle>
							</CardHeader>
							<CardContent>
								<Textarea
									id="general_wedding_notes"
									name="general_wedding_notes"
									value={props.weddingData.general_wedding_notes}
									onChange={(e) => updateData({ general_wedding_notes: e.target.value })}
									className="bg-gray-100 text-lg"
									rows={4}
								/>
							</CardContent>
						</Card>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="wedding-information">
					<AccordionTrigger className="text-lg">Wedding Information</AccordionTrigger>
					<AccordionContent className="grid grid-cols-2 gap-4">

						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Event Details</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_event_details_marriage_time"
										className="text-base text-gray-600 font-light"
									>
										Marriage Time
									</Label>
									<Select
										value={props.weddingData.wedding_information_event_details_marriage_time ? props.weddingData.wedding_information_event_details_marriage_time.toString() : ""}
										onValueChange={(value) => updateData({ wedding_information_event_details_marriage_time: value })}
									>
										<SelectTrigger
											id="wedding_information_event_details_marriage_time"
											data-testid="wedding_information_event_details_marriage_time"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Pick a Time" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											{times.map((timeRow, index) => <SelectItem key={index} value={timeRow.value} className="text-lg">{timeRow.label}</SelectItem>)}
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_event_details_marriage_date"
										className="text-base text-gray-600 font-light mt-5"
									>
										Wedding Date
									</Label>
									<Input
										id="wedding_information_event_details_marriage_date"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_event_details_marriage_date)}
										onChange={(e) => updateData({ wedding_information_event_details_marriage_date: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_event_details_marriage_location"
										className="text-base text-gray-600 font-light mt-5"
									>
										Marriage Location
									</Label>
									<Input
										id="wedding_information_event_details_marriage_location"
										type="text"
										name="wedding_information_event_details_marriage_location"
										onChange={(e) => updateData({ wedding_information_event_details_marriage_location: e.target.value })}
										value={props.weddingData.wedding_information_event_details_marriage_location}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_event_details_marriage_city"
										className="text-base text-gray-600 font-light mt-5"
									>
										City, town or suburb of the place of ceremony
									</Label>
									<Input
										id="wedding_information_event_details_marriage_city"
										type="text"
										name="wedding_information_event_details_marriage_city"
										onChange={(e) => updateData({ wedding_information_event_details_marriage_city: e.target.value })}
										value={props.weddingData.wedding_information_event_details_marriage_city}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_event_details_marriage_postcode"
										className="text-base text-gray-600 font-light mt-5"
									>
										Postcode
									</Label>
									<Input
										id="wedding_information_event_details_marriage_postcode"
										type="text"
										name="wedding_information_event_details_marriage_postcode"
										onChange={(e) => updateData({ wedding_information_event_details_marriage_postcode: e.target.value })}
										value={props.weddingData.wedding_information_event_details_marriage_postcode}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_event_details_marriage_state_australia"
										className="text-base text-gray-600 font-light mt-5"
									>
										Marriage State (Australia)
									</Label>
									<Select
										value={props.weddingData.wedding_information_event_details_marriage_state_australia}
										onValueChange={(value) => {
											//
											const newOtherValue = value !== "Other" ? "" : props.weddingData.wedding_information_event_details_marriage_state_other
											//
											updateData({
												...props.weddingData,
												wedding_information_event_details_marriage_state_australia: value,
												wedding_information_event_details_marriage_state_other: newOtherValue
											})
											//
										}}
									>
										<SelectTrigger
											id="wedding_information_event_details_marriage_state_australia"
											data-testid="wedding_information_event_details_marriage_state_australia"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an Australian state or Other" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
											<SelectItem value="Other" className="text-lg">Other</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_event_details_marriage_state_other"
										className="text-base text-gray-600 font-light mt-5"
									>
										Marriage State (Other)
									</Label>
									<Input
										id="wedding_information_event_details_marriage_state_other"
										type="text"
										name="wedding_information_event_details_marriage_state_other"
										disabled={props.weddingData.wedding_information_event_details_marriage_state_australia !== "Other"}
										onChange={(e) => updateData({ wedding_information_event_details_marriage_state_other: e.target.value })}
										value={props.weddingData.wedding_information_event_details_marriage_state_other}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_event_details_leave_place_solemnized_empty"
										className="text-base text-gray-600 font-light mt-5"
									>
										Leave 'Place marriage solemnised' Empty on NOIM?
									</Label>
									<Select
										value={props.weddingData.wedding_information_event_details_leave_place_solemnized_empty}
										onValueChange={(value) => updateData({ wedding_information_event_details_leave_place_solemnized_empty: value })}
									>
										<SelectTrigger
											id="wedding_information_event_details_leave_place_solemnized_empty"
											data-testid="wedding_information_event_details_leave_place_solemnized_empty"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Leave 'Place marriage solemnised' Empty on NOIM?" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>
							</CardContent>
						</Card>

						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Extra Dec/Cert Info</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_marriage_witness_1"
										className="text-base text-gray-600 font-light"
									>
										Marriage Witness 1
									</Label>
									<Input
										id="wedding_information_marriage_witness_1"
										type="text"
										name="wedding_information_marriage_witness_1"
										onChange={(e) => updateData({ wedding_information_marriage_witness_1: e.target.value })}
										value={props.weddingData.wedding_information_marriage_witness_1}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_marriage_witness_2"
										className="text-base text-gray-600 font-light mt-5"
									>
										Marriage Witness 2
									</Label>
									<Input
										id="wedding_information_marriage_witness_2"
										type="text"
										name="wedding_information_marriage_witness_2"
										onChange={(e) => updateData({ wedding_information_marriage_witness_2: e.target.value })}
										value={props.weddingData.wedding_information_marriage_witness_2}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>
								<div className="mt-5">
									<CardTitle className="underline text-center">Party 1</CardTitle>
								</div>
								<Button
									variant={"default"}
									className="mt-4 w-full text-lg font-light"
									disabled={props.status === "I"}
									onClick={() => {
										//
										updateData({
											...props.weddingData,
											wedding_information_person_1_declaration_place: props.weddingData.wedding_information_person_2_declaration_place,
											wedding_information_person_1_declaration_date: props.weddingData.wedding_information_person_2_declaration_date
										})
										//
									}}
								>
									Copy from party 2
								</Button>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_declaration_place"
										className="text-base text-gray-600 font-light mt-5"
									>
										Declaration Place
									</Label>
									<Textarea
										id="wedding_information_person_1_declaration_place"
										// type="text"
										rows={3}
										name="wedding_information_person_1_declaration_place"
										onChange={(e) => updateData({ wedding_information_person_1_declaration_place: e.target.value })}
										value={props.weddingData.wedding_information_person_1_declaration_place}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_declaration_date"
										className="text-base text-gray-600 font-light mt-5"
									>
										Declaration Date
									</Label>
									<Input
										id="wedding_information_person_1_declaration_date"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_person_1_declaration_date)}
										onChange={(e) => updateData({ wedding_information_person_1_declaration_date: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="mt-5">
									<CardTitle className="underline text-center">Party 2</CardTitle>
								</div>
								<Button
									className="mt-4 w-full text-lg font-light"
									disabled={props.status === "I"}
									onClick={() => {
										updateData({
											...props.weddingData,
											wedding_information_person_2_declaration_place: props.weddingData.wedding_information_person_1_declaration_place,
											wedding_information_person_2_declaration_date: props.weddingData.wedding_information_person_1_declaration_date
										})
									}}
								>
									Copy from party 1
								</Button>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_declaration_place"
										className="text-base text-gray-600 font-light mt-5"
									>
										Declaration Place
									</Label>
									<Textarea
										id="wedding_information_person_2_declaration_place"
										// type="text"
										rows={3}
										name="wedding_information_person_2_declaration_place"
										onChange={(e) => updateData({ wedding_information_person_2_declaration_place: e.target.value })}
										value={props.weddingData.wedding_information_person_2_declaration_place}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_declaration_date"
										className="text-base text-gray-600 font-light mt-5"
									>
										Declaration Date
									</Label>
									<Input
										id="wedding_information_person_2_declaration_date"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_person_2_declaration_date)}
										onChange={(e) => updateData({ wedding_information_person_2_declaration_date: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_ceremony_used"
										className="text-base text-gray-600 font-light mt-5"
									>
										Ceremony Used
									</Label>
									<Select
										value={props.weddingData.wedding_information_ceremony_used}
										onValueChange={(value) => updateData({ wedding_information_ceremony_used: value })}
									>
										<SelectTrigger
											id="wedding_information_ceremony_used"
											data-testid="wedding_information_ceremony_used"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="civil ceremony according to the Marriage Act 1961" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="civil ceremony according to the Marriage Act 1961" className="text-lg">civil ceremony according to the Marriage Act 1961</SelectItem>
											<SelectItem value="Religious rites" className="text-lg">Religious rites</SelectItem>
										</SelectContent>
									</Select>
								</div>

							</CardContent>
						</Card>

						<Card className="col-span-1 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Extra NOIM Info</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_extra_noim_date_noim_received_by_celebrant"
										className="text-base text-gray-600 font-light mt-3"
									>
										Date NOIM Received by Celebrant
									</Label>
									<Input
										id="wedding_information_extra_noim_date_noim_received_by_celebrant"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_extra_noim_date_noim_received_by_celebrant)}
										onChange={(e) => updateData({ wedding_information_extra_noim_date_noim_received_by_celebrant: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_extra_noim_rites_used"
										className="text-base text-gray-600 font-light mt-5"
									>
										Rites Used
									</Label>
									<Input
										id="wedding_information_extra_noim_rites_used"
										type="text"
										name="wedding_information_extra_noim_rites_used"
										onChange={(e) => updateData({ wedding_information_extra_noim_rites_used: e.target.value })}
										value={props.weddingData.wedding_information_extra_noim_rites_used}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_noim_signature_date"
										className="text-base text-gray-600 font-light mt-5"
									>
										Signature Date - Person 1
									</Label>
									<Input
										id="wedding_information_person_1_noim_signature_date"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_person_1_noim_signature_date)}
										onChange={(e) => updateData({ wedding_information_person_1_noim_signature_date: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_noim_signature_date"
										className="text-base text-gray-600 font-light mt-5"
									>
										Signature Date - Person 2
									</Label>
									<Input
										id="wedding_information_person_2_noim_signature_date"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_person_2_noim_signature_date)}
										onChange={(e) => updateData({ wedding_information_person_2_noim_signature_date: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_extra_noim_marriage_witness_1"
										className="text-base text-gray-600 font-light mt-5"
									>
										Marriage Witness 1
									</Label>
									<Input
										id="wedding_information_extra_noim_marriage_witness_1"
										type="text"
										name="wedding_information_extra_noim_marriage_witness_1"
										onChange={(e) => updateData({ wedding_information_extra_noim_marriage_witness_1: e.target.value })}
										value={props.weddingData.wedding_information_extra_noim_marriage_witness_1}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_extra_noim_marriage_witness_2"
										className="text-base text-gray-600 font-light mt-5"
									>
										Marriage Witness 2
									</Label>
									<Input
										id="wedding_information_extra_noim_marriage_witness_2"
										type="text"
										name="wedding_information_extra_noim_marriage_witness_2"
										onChange={(e) => updateData({ wedding_information_extra_noim_marriage_witness_2: e.target.value })}
										value={props.weddingData.wedding_information_extra_noim_marriage_witness_2}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_extra_noim_authorized_witness_1"
										className="text-base text-gray-600 font-light mt-5"
									>
										Authorized Witness 1
									</Label>
									<Input
										id="wedding_information_extra_noim_authorized_witness_1"
										type="text"
										name="wedding_information_extra_noim_authorized_witness_1"
										onChange={(e) => updateData({ wedding_information_extra_noim_authorized_witness_1: e.target.value })}
										value={props.weddingData.wedding_information_extra_noim_authorized_witness_1}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_extra_noim_authorized_witness_2"
										className="text-base text-gray-600 font-light mt-5"
									>
										Authorized Witness 2
									</Label>
									<Input
										id="wedding_information_extra_noim_authorized_witness_2"
										type="text"
										name="wedding_information_extra_noim_authorized_witness_2"
										onChange={(e) => updateData({ wedding_information_extra_noim_authorized_witness_2: e.target.value })}
										value={props.weddingData.wedding_information_extra_noim_authorized_witness_2}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 ring-offset-0"
									/>
								</div>

							</CardContent>
						</Card>

					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="party-1-2">
					<AccordionTrigger className="text-lg">Party 1/2</AccordionTrigger>
					<AccordionContent className="grid grid-cols-2 gap-4">

						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Party 1</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_description"
										className="text-base text-gray-600 font-light mt-5"
									>
										Description
									</Label>
									<Select
										name="party12_person_1_description"
										value={props.weddingData.party12_person_1_description}
										onValueChange={(value) => updateData({ party12_person_1_description: value })}
									>
										<SelectTrigger
											id="party12_person_1_description"
											data-testid="party12_person_1_description"
											className="w-full bg-gray-100 text-lg"
											name="party12_person_1_description"
										>
											<SelectValue placeholder="No" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Partner" className="text-lg">Partner</SelectItem>
											<SelectItem value="Bride" className="text-lg">Bride</SelectItem>
											<SelectItem value="Groom" className="text-lg">Groom</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_surname"
										className="text-base text-gray-600 font-light mt-5"
									>
										Surname
									</Label>
									<Input
										id="party12_person_1_surname"
										type="text"
										name="party12_person_1_surname"
										onChange={(e) => updateData({ party12_person_1_surname: e.target.value })}
										value={props.weddingData.party12_person_1_surname}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_sex"
										className="text-base text-gray-600 font-light mt-5"
									>
										Sex
									</Label>
									<Select
										name="party12_person_1_sex"
										value={props.weddingData.party12_person_1_sex}
										onValueChange={(value) => updateData({ party12_person_1_sex: value })}
									>
										<SelectTrigger
											id="party12_person_1_sex"
											data-testid="party12_person_1_sex"
											className="w-full bg-gray-100 text-lg"
											name="party12_person_1_sex"
										>
											<SelectValue placeholder="No" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Non-Binary" className="text-lg">Non-Binary</SelectItem>
											<SelectItem value="Female" className="text-lg">Female</SelectItem>
											<SelectItem value="Male" className="text-lg">Male</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_first_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										First Name and Other
									</Label>
									<Input
										id="party12_person_1_first_name"
										type="text"
										name="party12_person_1_first_name"
										onChange={(e) => updateData({ party12_person_1_first_name: e.target.value })}
										value={props.weddingData.party12_person_1_first_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_occupation"
										className="text-base text-gray-600 font-light mt-5"
									>
										Occupation
									</Label>
									<Input
										id="party12_person_1_occupation"
										type="text"
										name="party12_person_1_occupation"
										onChange={(e) => updateData({ party12_person_1_occupation: e.target.value })}
										value={props.weddingData.party12_person_1_occupation}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<Button
									variant={"default"}
									className="mt-4 w-full text-lg font-light"
									disabled={props.status === "I"}
									onClick={() => {
										//
										updateData({
											...props.weddingData,
											party12_person_1_address_1: props.weddingData.party12_person_2_address_1,
											party12_person_1_address_2: props.weddingData.party12_person_2_address_2,
											party12_person_1_city: props.weddingData.party12_person_2_city,
											party12_person_1_postcode: props.weddingData.party12_person_2_postcode,
											party12_person_1_state: props.weddingData.party12_person_2_state,
											party12_person_1_state_other: props.weddingData.party12_person_2_state_other,
										})
										//
									}}
								>
									Copy Address from party 2
								</Button>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_address_1"
										className="text-base text-gray-600 font-light mt-5"
									>
										Address (1)
									</Label>
									<Input
										id="party12_person_1_address_1"
										type="text"
										name="party12_person_1_address_1"
										onChange={(e) => updateData({ party12_person_1_address_1: e.target.value })}
										value={props.weddingData.party12_person_1_address_1}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_address_2"
										className="text-base text-gray-600 font-light mt-5"
									>
										Address (2)
									</Label>
									<Input
										id="party12_person_1_address_2"
										type="text"
										name="party12_person_1_address_2"
										onChange={(e) => updateData({ party12_person_1_address_2: e.target.value })}
										value={props.weddingData.party12_person_1_address_2}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_city"
										className="text-base text-gray-600 font-light mt-5"
									>
										City
									</Label>
									<Input
										id="party12_person_1_city"
										type="text"
										name="party12_person_1_city"
										onChange={(e) => updateData({ party12_person_1_city: e.target.value })}
										value={props.weddingData.party12_person_1_city}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_postcode"
										className="text-base text-gray-600 font-light mt-5"
									>
										Postcode
									</Label>
									<Input
										id="party12_person_1_postcode"
										type="text"
										name="party12_person_1_postcode"
										onChange={(e) => updateData({ party12_person_1_postcode: e.target.value })}
										value={props.weddingData.party12_person_1_postcode}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_state"
										className="text-base text-gray-600 font-light mt-5"
									>
										State
									</Label>
									<Select
										value={props.weddingData.party12_person_1_state}
										onValueChange={(value) => {
											//
											const newOtherValue = value !== "Other" ? "" : props.weddingData.party12_person_1_state_other
											//
											updateData({
												...props.weddingData,
												party12_person_1_state: value,
												party12_person_1_state_other: newOtherValue
											})
											//
										}}
									>
										<SelectTrigger
											id="party12_person_1_state"
											data-testid="party12_person_1_state"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an Australian state or Other" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
											<SelectItem value="Other" className="text-lg">Other</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_state_other"
										className="text-base text-gray-600 font-light mt-5"
									>
										State (Other)
									</Label>
									<Input
										id="party12_person_1_state_other"
										type="text"
										name="party12_person_1_state_other"
										disabled={props.weddingData.party12_person_1_state !== "Other"}
										onChange={(e) => updateData({ party12_person_1_state_other: e.target.value })}
										value={props.weddingData.party12_person_1_state_other}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_town_of_birth"
										className="text-base text-gray-600 font-light mt-5"
									>
										City/Town of Birth
									</Label>
									<Input
										id="party12_person_1_town_of_birth"
										type="text"
										name="party12_person_1_town_of_birth"
										onChange={(e) => updateData({ party12_person_1_town_of_birth: e.target.value })}
										value={props.weddingData.party12_person_1_town_of_birth}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_birthplace_state"
										className="text-base text-gray-600 font-light mt-5"
									>
										Birthplace State (Australia)
									</Label>
									<Select
										value={props.weddingData.party12_person_1_birthplace_state}
										onValueChange={(value) => {
											//
											const newOtherValue = value !== "Other" ? "" : props.weddingData.party12_person_1_birthplace_state_other
											//
											updateData({
												...props.weddingData,
												party12_person_1_birthplace_state: value,
												party12_person_1_birthplace_state_other: newOtherValue
											})
											//
										}}
									>
										<SelectTrigger
											id="party12_person_1_birthplace_state"
											data-testid="party12_person_1_birthplace_state"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an Australian state or Other" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
											<SelectItem value="Other" className="text-lg">Other</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_birthplace_state_other"
										className="text-base text-gray-600 font-light mt-5"
									>
										Country of Birth (If not Australia)
									</Label>
									<Input
										id="party12_person_1_birthplace_state_other"
										type="text"
										name="party12_person_1_birthplace_state_other"
										disabled={props.weddingData.party12_person_1_birthplace_state !== "Other"}
										onChange={(e) => updateData({ party12_person_1_birthplace_state_other: e.target.value })}
										value={props.weddingData.party12_person_1_birthplace_state_other}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_conjugal"
										className="text-base text-gray-600 font-light mt-5"
									>
										Conjugal
									</Label>
									<Select
										value={props.weddingData.party12_person_1_conjugal}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												party12_person_1_conjugal: value
											})
											//
										}}
									>
										<SelectTrigger
											id="party12_person_1_conjugal"
											data-testid="party12_person_1_conjugal"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select a conjugal status" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Never validly married" className="text-lg">Never validly married</SelectItem>
											<SelectItem value="Widowed" className="text-lg">Widowed</SelectItem>
											<SelectItem value="Divorced" className="text-lg">Divorced</SelectItem>
											<SelectItem value="Divorce pending" className="text-lg">Divorce pending</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_dob"
										className="text-base text-gray-600 font-light mt-5"
									>
										Date of Birth
									</Label>
									<Input
										id="party12_person_1_dob"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.party12_person_1_dob)}
										onChange={(e) => updateData({ party12_person_1_dob: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_parent_1_full_current_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 1 - Full Current Name
									</Label>
									<Input
										id="party12_person_1_parent_1_full_current_name"
										type="text"
										name="party12_person_1_parent_1_full_current_name"
										onChange={(e) => updateData({ party12_person_1_parent_1_full_current_name: e.target.value })}
										value={props.weddingData.party12_person_1_parent_1_full_current_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_parent_1_full_birth_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 1 - Full Birth Name
									</Label>
									<Input
										id="party12_person_1_parent_1_full_birth_name"
										type="text"
										name="party12_person_1_parent_1_full_birth_name"
										onChange={(e) => updateData({ party12_person_1_parent_1_full_birth_name: e.target.value })}
										value={props.weddingData.party12_person_1_parent_1_full_birth_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_parent_2_full_current_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 2 - Full Current Name
									</Label>
									<Input
										id="party12_person_1_parent_2_full_current_name"
										type="text"
										name="party12_person_1_parent_2_full_current_name"
										onChange={(e) => updateData({ party12_person_1_parent_2_full_current_name: e.target.value })}
										value={props.weddingData.party12_person_1_parent_2_full_current_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_parent_2_full_birth_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 2 - Full Birth Name
									</Label>
									<Input
										id="party12_person_1_parent_2_full_birth_name"
										type="text"
										name="party12_person_1_parent_2_full_birth_name"
										onChange={(e) => updateData({ party12_person_1_parent_2_full_birth_name: e.target.value })}
										value={props.weddingData.party12_person_1_parent_2_full_birth_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_parent_1_country_of_birth"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 1 - Country of Birth
									</Label>
									<Input
										id="party12_person_1_parent_1_country_of_birth"
										type="text"
										name="party12_person_1_parent_1_country_of_birth"
										onChange={(e) => updateData({ party12_person_1_parent_1_country_of_birth: e.target.value })}
										value={props.weddingData.party12_person_1_parent_1_country_of_birth}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_1_parent_2_country_of_birth"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 2 - Country of Birth
									</Label>
									<Input
										id="party12_person_1_parent_2_country_of_birth"
										type="text"
										name="party12_person_1_parent_2_country_of_birth"
										onChange={(e) => updateData({ party12_person_1_parent_2_country_of_birth: e.target.value })}
										value={props.weddingData.party12_person_1_parent_2_country_of_birth}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>

							</CardContent>
						</Card>

						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Party 2</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_description"
										className="text-base text-gray-600 font-light mt-5"
									>
										Description
									</Label>
									<Select
										name="party12_person_2_description"
										value={props.weddingData.party12_person_2_description}
										onValueChange={(value) => updateData({ party12_person_2_description: value })}
									>
										<SelectTrigger
											id="party12_person_2_description"
											data-testid="party12_person_2_description"
											className="w-full bg-gray-100 text-lg"
											name="party12_person_2_description"
										>
											<SelectValue placeholder="No" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Partner" className="text-lg">Partner</SelectItem>
											<SelectItem value="Bride" className="text-lg">Bride</SelectItem>
											<SelectItem value="Groom" className="text-lg">Groom</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_surname"
										className="text-base text-gray-600 font-light mt-5"
									>
										Surname
									</Label>
									<Input
										id="party12_person_2_surname"
										type="text"
										name="party12_person_2_surname"
										onChange={(e) => updateData({ party12_person_2_surname: e.target.value })}
										value={props.weddingData.party12_person_2_surname}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_sex"
										className="text-base text-gray-600 font-light mt-5"
									>
										Sex
									</Label>
									<Select
										name="party12_person_2_sex"
										value={props.weddingData.party12_person_2_sex}
										onValueChange={(value) => updateData({ party12_person_2_sex: value })}
									>
										<SelectTrigger
											id="party12_person_2_sex"
											data-testid="party12_person_2_sex"
											className="w-full bg-gray-100 text-lg"
											name="party12_person_2_sex"
										>
											<SelectValue placeholder="No" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Non-Binary" className="text-lg">Non-Binary</SelectItem>
											<SelectItem value="Female" className="text-lg">Female</SelectItem>
											<SelectItem value="Male" className="text-lg">Male</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_first_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										First Name and Other
									</Label>
									<Input
										id="party12_person_2_first_name"
										type="text"
										name="party12_person_2_first_name"
										onChange={(e) => updateData({ party12_person_2_first_name: e.target.value })}
										value={props.weddingData.party12_person_2_first_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_occupation"
										className="text-base text-gray-600 font-light mt-5"
									>
										Occupation
									</Label>
									<Input
										id="party12_person_2_occupation"
										type="text"
										name="party12_person_2_occupation"
										onChange={(e) => updateData({ party12_person_2_occupation: e.target.value })}
										value={props.weddingData.party12_person_2_occupation}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<Button
									variant={"default"}
									className="mt-4 w-full text-lg font-light"
									disabled={props.status === "I"}
									onClick={() => {
										//
										updateData({
											...props.weddingData,
											party12_person_2_address_1: props.weddingData.party12_person_1_address_1,
											party12_person_2_address_2: props.weddingData.party12_person_1_address_2,
											party12_person_2_city: props.weddingData.party12_person_1_city,
											party12_person_2_postcode: props.weddingData.party12_person_1_postcode,
											party12_person_2_state: props.weddingData.party12_person_1_state,
											party12_person_2_state_other: props.weddingData.party12_person_1_state_other,
										})
										//
									}}
								>
									Copy Address from party 1
								</Button>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_address_1"
										className="text-base text-gray-600 font-light mt-5"
									>
										Address (1)
									</Label>
									<Input
										id="party12_person_2_address_1"
										type="text"
										name="party12_person_2_address_1"
										onChange={(e) => updateData({ party12_person_2_address_1: e.target.value })}
										value={props.weddingData.party12_person_2_address_1}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_address_2"
										className="text-base text-gray-600 font-light mt-5"
									>
										Address (2)
									</Label>
									<Input
										id="party12_person_2_address_2"
										type="text"
										name="party12_person_2_address_2"
										onChange={(e) => updateData({ party12_person_2_address_2: e.target.value })}
										value={props.weddingData.party12_person_2_address_2}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_city"
										className="text-base text-gray-600 font-light mt-5"
									>
										City
									</Label>
									<Input
										id="party12_person_2_city"
										type="text"
										name="party12_person_2_city"
										onChange={(e) => updateData({ party12_person_2_city: e.target.value })}
										value={props.weddingData.party12_person_2_city}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_postcode"
										className="text-base text-gray-600 font-light mt-5"
									>
										Postcode
									</Label>
									<Input
										id="party12_person_2_postcode"
										type="text"
										name="party12_person_2_postcode"
										onChange={(e) => updateData({ party12_person_2_postcode: e.target.value })}
										value={props.weddingData.party12_person_2_postcode}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_state"
										className="text-base text-gray-600 font-light mt-5"
									>
										State
									</Label>
									<Select
										value={props.weddingData.party12_person_2_state}
										onValueChange={(value) => {
											//
											const newOtherValue = value !== "Other" ? "" : props.weddingData.party12_person_2_state_other
											//
											updateData({
												...props.weddingData,
												party12_person_2_state: value,
												party12_person_2_state_other: newOtherValue
											})
											//
										}}
									>
										<SelectTrigger
											id="party12_person_2_state"
											data-testid="party12_person_2_state"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an Australian state or Other" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
											<SelectItem value="Other" className="text-lg">Other</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_state_other"
										className="text-base text-gray-600 font-light mt-5"
									>
										State (Other)
									</Label>
									<Input
										id="party12_person_2_state_other"
										type="text"
										name="party12_person_2_state_other"
										disabled={props.weddingData.party12_person_2_state !== "Other"}
										onChange={(e) => updateData({ party12_person_2_state_other: e.target.value })}
										value={props.weddingData.party12_person_2_state_other}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_town_of_birth"
										className="text-base text-gray-600 font-light mt-5"
									>
										City/Town of Birth
									</Label>
									<Input
										id="party12_person_2_town_of_birth"
										type="text"
										name="party12_person_2_town_of_birth"
										onChange={(e) => updateData({ party12_person_2_town_of_birth: e.target.value })}
										value={props.weddingData.party12_person_2_town_of_birth}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_birthplace_state"
										className="text-base text-gray-600 font-light mt-5"
									>
										Birthplace State (Australia)
									</Label>
									<Select
										value={props.weddingData.party12_person_2_birthplace_state}
										onValueChange={(value) => {
											//
											const newOtherValue = value !== "Other" ? "" : props.weddingData.party12_person_2_birthplace_state_other
											//
											updateData({
												...props.weddingData,
												party12_person_2_birthplace_state: value,
												party12_person_2_birthplace_state_other: newOtherValue
											})
											//
										}}
									>
										<SelectTrigger
											id="party12_person_2_birthplace_state"
											data-testid="party12_person_2_birthplace_state"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an Australian state or Other" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											{australian_states.map((stateRow, index) => <SelectItem key={index} value={stateRow.value} className="text-lg">{stateRow.label}</SelectItem>)}
											<SelectItem value="Other" className="text-lg">Other</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_birthplace_state_other"
										className="text-base text-gray-600 font-light mt-5"
									>
										Country of Birth (If not Australia)
									</Label>
									<Input
										id="party12_person_2_birthplace_state_other"
										type="text"
										name="party12_person_2_birthplace_state_other"
										disabled={props.weddingData.party12_person_2_birthplace_state !== "Other"}
										onChange={(e) => updateData({ party12_person_2_birthplace_state_other: e.target.value })}
										value={props.weddingData.party12_person_2_birthplace_state_other}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_conjugal"
										className="text-base text-gray-600 font-light mt-5"
									>
										Conjugal
									</Label>
									<Select
										value={props.weddingData.party12_person_2_conjugal}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												party12_person_2_conjugal: value
											})
											//
										}}
									>
										<SelectTrigger
											id="party12_person_2_conjugal"
											data-testid="party12_person_2_conjugal"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select a conjugal status" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Never validly married" className="text-lg">Never validly married</SelectItem>
											<SelectItem value="Widowed" className="text-lg">Widowed</SelectItem>
											<SelectItem value="Divorced" className="text-lg">Divorced</SelectItem>
											<SelectItem value="Divorce pending" className="text-lg">Divorce pending</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_dob"
										className="text-base text-gray-600 font-light mt-5"
									>
										Date of Birth
									</Label>
									<Input
										id="party12_person_2_dob"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.party12_person_2_dob)}
										onChange={(e) => updateData({ party12_person_2_dob: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_parent_1_full_current_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 1 - Full Current Name
									</Label>
									<Input
										id="party12_person_2_parent_1_full_current_name"
										type="text"
										name="party12_person_2_parent_1_full_current_name"
										onChange={(e) => updateData({ party12_person_2_parent_1_full_current_name: e.target.value })}
										value={props.weddingData.party12_person_2_parent_1_full_current_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_parent_1_full_birth_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 1 - Full Birth Name
									</Label>
									<Input
										id="party12_person_2_parent_1_full_birth_name"
										type="text"
										name="party12_person_2_parent_1_full_birth_name"
										onChange={(e) => updateData({ party12_person_2_parent_1_full_birth_name: e.target.value })}
										value={props.weddingData.party12_person_2_parent_1_full_birth_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_parent_2_full_current_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 2 - Full Current Name
									</Label>
									<Input
										id="party12_person_2_parent_2_full_current_name"
										type="text"
										name="party12_person_2_parent_2_full_current_name"
										onChange={(e) => updateData({ party12_person_2_parent_2_full_current_name: e.target.value })}
										value={props.weddingData.party12_person_2_parent_2_full_current_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_parent_2_full_birth_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 2 - Full Birth Name
									</Label>
									<Input
										id="party12_person_2_parent_2_full_birth_name"
										type="text"
										name="party12_person_2_parent_2_full_birth_name"
										onChange={(e) => updateData({ party12_person_2_parent_2_full_birth_name: e.target.value })}
										value={props.weddingData.party12_person_2_parent_2_full_birth_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_parent_1_country_of_birth"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 1 - Country of Birth
									</Label>
									<Input
										id="party12_person_2_parent_1_country_of_birth"
										type="text"
										name="party12_person_2_parent_1_country_of_birth"
										onChange={(e) => updateData({ party12_person_2_parent_1_country_of_birth: e.target.value })}
										value={props.weddingData.party12_person_2_parent_1_country_of_birth}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="party12_person_2_parent_2_country_of_birth"
										className="text-base text-gray-600 font-light mt-5"
									>
										Parent 2 - Country of Birth
									</Label>
									<Input
										id="party12_person_2_parent_2_country_of_birth"
										type="text"
										name="party12_person_2_parent_2_country_of_birth"
										onChange={(e) => updateData({ party12_person_2_parent_2_country_of_birth: e.target.value })}
										value={props.weddingData.party12_person_2_parent_2_country_of_birth}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>

							</CardContent>
						</Card>

					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="noim-particulars">
					<AccordionTrigger className="text-lg">NOIM Particulars</AccordionTrigger>
					<AccordionContent className="grid grid-cols-2 gap-4">
						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Party 1</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_evidence"
										className="text-base text-gray-600 font-light mt-5"
									>
										Evidence of Date and Place of Birth
									</Label>
									<Select
										value={props.weddingData.noim_particulars_person_1_evidence}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_person_1_evidence: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_person_1_evidence"
											data-testid="noim_particulars_person_1_evidence"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Select" className="text-lg">Please select an option</SelectItem>
											<SelectItem value="Birth certificate or official extract" className="text-lg">Birth certificate or official extract</SelectItem>
											<SelectItem value="Australian passport" className="text-lg">Australian passport</SelectItem>
											<SelectItem value="Statutory declaration" className="text-lg">Statutory declaration</SelectItem>
											<SelectItem value="Overseas passport" className="text-lg">Overseas passport</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_evidence_document_number"
										className="text-base text-gray-600 font-light mt-5"
									>
										Document Number
									</Label>
									<Input
										id="noim_particulars_person_1_evidence_document_number"
										type="text"
										name="noim_particulars_person_1_evidence_document_number"
										onChange={(e) => updateData({ noim_particulars_person_1_evidence_document_number: e.target.value })}
										value={props.weddingData.noim_particulars_person_1_evidence_document_number}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_identity_confirmed"
										className="text-base text-gray-600 font-light mt-5"
									>
										Identity Confirmed
									</Label>
									<Select
										value={props.weddingData.noim_particulars_person_1_identity_confirmed}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_person_1_identity_confirmed: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_person_1_identity_confirmed"
											data-testid="noim_particulars_person_1_identity_confirmed"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_type_of_id"
										className="text-base text-gray-600 font-light mt-5"
									>
										Type of ID Document Provided
									</Label>
									<Input
										id="noim_particulars_person_1_type_of_id"
										type="text"
										name="noim_particulars_person_1_type_of_id"
										onChange={(e) => updateData({ noim_particulars_person_1_type_of_id: e.target.value })}
										value={props.weddingData.noim_particulars_person_1_type_of_id}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_id_document_number"
										className="text-base text-gray-600 font-light mt-5"
									>
										Document Number
									</Label>
									<Input
										id="noim_particulars_person_1_id_document_number"
										type="text"
										name="noim_particulars_person_1_id_document_number"
										onChange={(e) => updateData({ noim_particulars_person_1_id_document_number: e.target.value })}
										value={props.weddingData.noim_particulars_person_1_id_document_number}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_state_territory_or_country"
										className="text-base text-gray-600 font-light mt-5"
									>
										State, Territory, or Country of Issue
									</Label>
									<Input
										id="noim_particulars_person_1_state_territory_or_country"
										type="text"
										name="noim_particulars_person_1_state_territory_or_country"
										onChange={(e) => updateData({ noim_particulars_person_1_state_territory_or_country: e.target.value })}
										value={props.weddingData.noim_particulars_person_1_state_territory_or_country}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_date_last_marriage_ended"
										className="text-base text-gray-600 font-light mt-5"
									>
										Date Last Marriage Ended
									</Label>
									<Input
										id="wedding_information_person_1_date_last_marriage_ended"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_person_1_date_last_marriage_ended)}
										onChange={(e) => updateData({ wedding_information_person_1_date_last_marriage_ended: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_how_last_marriage_ended"
										className="text-base text-gray-600 font-light mt-5"
									>
										Evidence Sighted of How Last Marriage Ended
									</Label>
									<Select
										value={props.weddingData.noim_particulars_person_1_how_last_marriage_ended}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_person_1_how_last_marriage_ended: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_person_1_how_last_marriage_ended"
											data-testid="noim_particulars_person_1_how_last_marriage_ended"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value=" " className="text-lg">Please select an option</SelectItem>
											<SelectItem value="Death" className="text-lg">Death</SelectItem>
											<SelectItem value="Nullity" className="text-lg">Nullity</SelectItem>
											<SelectItem value="Divorce" className="text-lg">Divorce</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_death_certificate"
										className="text-base text-gray-600 font-light mt-5"
									>
										Death Certificate Number
									</Label>
									<Input
										id="noim_particulars_person_1_death_certificate"
										type="text"
										name="noim_particulars_person_1_death_certificate"
										disabled={props.weddingData.noim_particulars_person_1_how_last_marriage_ended !== "Death"}
										onChange={(e) => updateData({ noim_particulars_person_1_death_certificate: e.target.value })}
										value={props.weddingData.noim_particulars_person_1_death_certificate}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_court_location"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Location
									</Label>
									<Input
										id="noim_particulars_person_1_court_location"
										type="text"
										name="noim_particulars_person_1_court_location"
										disabled={!(props.weddingData.noim_particulars_person_1_how_last_marriage_ended === "Nullity" || props.weddingData.noim_particulars_person_1_how_last_marriage_ended === "Divorce")}
										onChange={(e) => updateData({ noim_particulars_person_1_court_location: e.target.value })}
										value={props.weddingData.noim_particulars_person_1_court_location}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_1_document_referred"
										className="text-base text-gray-600 font-light mt-5"
									>
										Document Referred to in s42(5A)?
									</Label>
									<Select
										value={props.weddingData.noim_particulars_person_1_document_referred}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_person_1_document_referred: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_person_1_document_referred"
											data-testid="noim_particulars_person_1_document_referred"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_late_marriage_not_applicable"
										className="text-base text-gray-600 font-light mt-5"
									>
										Late Notice (Not Applicable)
									</Label>
									<Select
										value={props.weddingData.noim_particulars_late_marriage_not_applicable}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_late_marriage_not_applicable: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_late_marriage_not_applicable"
											data-testid="noim_particulars_late_marriage_not_applicable"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Off" className="text-lg">Off</SelectItem>
											<SelectItem value="Not Applicable" className="text-lg">Not Applicable</SelectItem>
											<SelectItem value="Granted" className="text-lg">Granted</SelectItem>
										</SelectContent>
									</Select>
								</div>

							</CardContent>
						</Card>

						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Party 2</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_evidence"
										className="text-base text-gray-600 font-light mt-5"
									>
										Evidence of Date and Place of Birth
									</Label>
									<Select
										value={props.weddingData.noim_particulars_person_2_evidence}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_person_2_evidence: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_person_2_evidence"
											data-testid="noim_particulars_person_2_evidence"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="Select" className="text-lg">Please select an option</SelectItem>
											<SelectItem value="Birth certificate or official extract" className="text-lg">Birth certificate or official extract</SelectItem>
											<SelectItem value="Australian passport" className="text-lg">Australian passport</SelectItem>
											<SelectItem value="Statutory declaration" className="text-lg">Statutory declaration</SelectItem>
											<SelectItem value="Overseas passport" className="text-lg">Overseas passport</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_evidence_document_number"
										className="text-base text-gray-600 font-light mt-5"
									>
										Document Number
									</Label>
									<Input
										id="noim_particulars_person_2_evidence_document_number"
										type="text"
										name="noim_particulars_person_2_evidence_document_number"
										onChange={(e) => updateData({ noim_particulars_person_2_evidence_document_number: e.target.value })}
										value={props.weddingData.noim_particulars_person_2_evidence_document_number}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_identity_confirmed"
										className="text-base text-gray-600 font-light mt-5"
									>
										Identity Confirmed
									</Label>
									<Select
										value={props.weddingData.noim_particulars_person_2_identity_confirmed}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_person_2_identity_confirmed: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_person_2_identity_confirmed"
											data-testid="noim_particulars_person_2_identity_confirmed"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_type_of_id"
										className="text-base text-gray-600 font-light mt-5"
									>
										Type of ID Document Provided
									</Label>
									<Input
										id="noim_particulars_person_2_type_of_id"
										type="text"
										name="noim_particulars_person_2_type_of_id"
										onChange={(e) => updateData({ noim_particulars_person_2_type_of_id: e.target.value })}
										value={props.weddingData.noim_particulars_person_2_type_of_id}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_id_document_number"
										className="text-base text-gray-600 font-light mt-5"
									>
										Document Number
									</Label>
									<Input
										id="noim_particulars_person_2_id_document_number"
										type="text"
										name="noim_particulars_person_2_id_document_number"
										onChange={(e) => updateData({ noim_particulars_person_2_id_document_number: e.target.value })}
										value={props.weddingData.noim_particulars_person_2_id_document_number}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_state_territory_or_country"
										className="text-base text-gray-600 font-light mt-5"
									>
										State, Territory, or Country of Issue
									</Label>
									<Input
										id="noim_particulars_person_2_state_territory_or_country"
										type="text"
										name="noim_particulars_person_2_state_territory_or_country"
										onChange={(e) => updateData({ noim_particulars_person_2_state_territory_or_country: e.target.value })}
										value={props.weddingData.noim_particulars_person_2_state_territory_or_country}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_date_last_marriage_ended"
										className="text-base text-gray-600 font-light mt-5"
									>
										Date Last Marriage Ended
									</Label>
									<Input
										id="wedding_information_person_2_date_last_marriage_ended"
										type="date"
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_person_2_date_last_marriage_ended)}
										onChange={(e) => updateData({ wedding_information_person_2_date_last_marriage_ended: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_how_last_marriage_ended"
										className="text-base text-gray-600 font-light mt-5"
									>
										Evidence Sighted of How Last Marriage Ended
									</Label>
									<Select
										value={props.weddingData.noim_particulars_person_2_how_last_marriage_ended}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_person_2_how_last_marriage_ended: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_person_2_how_last_marriage_ended"
											data-testid="noim_particulars_person_2_how_last_marriage_ended"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value=" " className="text-lg">Please select an option</SelectItem>
											<SelectItem value="Death" className="text-lg">Death</SelectItem>
											<SelectItem value="Nullity" className="text-lg">Nullity</SelectItem>
											<SelectItem value="Divorce" className="text-lg">Divorce</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_death_certificate"
										className="text-base text-gray-600 font-light mt-5"
									>
										Death Certificate Number
									</Label>
									<Input
										id="noim_particulars_person_2_death_certificate"
										type="text"
										name="noim_particulars_person_2_death_certificate"
										disabled={props.weddingData.noim_particulars_person_2_how_last_marriage_ended !== "Death"}
										onChange={(e) => updateData({ noim_particulars_person_2_death_certificate: e.target.value })}
										value={props.weddingData.noim_particulars_person_2_death_certificate}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_court_location"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Location
									</Label>
									<Input
										id="noim_particulars_person_2_court_location"
										type="text"
										name="noim_particulars_person_2_court_location"
										disabled={!(props.weddingData.noim_particulars_person_2_how_last_marriage_ended === "Nullity" || props.weddingData.noim_particulars_person_2_how_last_marriage_ended === "Divorce")}
										onChange={(e) => updateData({ noim_particulars_person_2_court_location: e.target.value })}
										value={props.weddingData.noim_particulars_person_2_court_location}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="noim_particulars_person_2_document_referred"
										className="text-base text-gray-600 font-light mt-5"
									>
										Document Referred to in s42(5A)?
									</Label>
									<Select
										value={props.weddingData.noim_particulars_person_2_document_referred}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												noim_particulars_person_2_document_referred: value
											})
											//
										}}
									>
										<SelectTrigger
											id="noim_particulars_person_2_document_referred"
											data-testid="noim_particulars_person_2_document_referred"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>

							</CardContent>
						</Card>

					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="related-under-18">
					<AccordionTrigger className="text-lg">Related/Under 18</AccordionTrigger>
					<AccordionContent className="grid grid-cols-2 gap-4">
						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Under 18 - Party 1</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_under_18"
										className="text-base text-gray-600 font-light mt-5"
									>
										Under 18?
									</Label>
									<Select
										value={props.weddingData.wedding_information_person_1_under_18}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												wedding_information_person_1_under_18: value
											})
											//
										}}
									>
										<SelectTrigger
											id="wedding_information_person_1_under_18"
											data-testid="wedding_information_person_1_under_18"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_under_18_court_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Name
									</Label>
									<Input
										id="wedding_information_person_1_under_18_court_name"
										type="text"
										name="wedding_information_person_1_under_18_court_name"
										disabled={props.weddingData.wedding_information_person_1_under_18 === "No"}
										onChange={(e) => updateData({ wedding_information_person_1_under_18_court_name: e.target.value })}
										value={props.weddingData.wedding_information_person_1_under_18_court_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_under_18_court_location"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Location
									</Label>
									<Input
										id="wedding_information_person_1_under_18_court_location"
										type="text"
										name="wedding_information_person_1_under_18_court_location"
										disabled={props.weddingData.wedding_information_person_1_under_18 === "No"}
										onChange={(e) => updateData({ wedding_information_person_1_under_18_court_location: e.target.value })}
										value={props.weddingData.wedding_information_person_1_under_18_court_location}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_under_18_court_date"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Date
									</Label>
									<Input
										id="wedding_information_person_1_under_18_court_date"
										type="date"
										disabled={props.weddingData.wedding_information_person_1_under_18 === "No"}
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_person_1_under_18_court_date)}
										onChange={(e) => updateData({ wedding_information_person_1_under_18_court_date: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_1_under_18_consent_received"
										className="text-base text-gray-600 font-light mt-5"
									>
										Consents Received?
									</Label>
									<Select
										disabled={props.weddingData.wedding_information_person_1_under_18 === "No"}
										value={props.weddingData.wedding_information_person_1_under_18_consent_received}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												wedding_information_person_1_under_18_consent_received: value
											})
											//
										}}
									>
										<SelectTrigger
											id="wedding_information_person_1_under_18_consent_received"
											data-testid="wedding_information_person_1_under_18_consent_received"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>

							</CardContent>
						</Card>

						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Under 18 - Party 2</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_under_18"
										className="text-base text-gray-600 font-light mt-5"
									>
										Under 18?
									</Label>
									<Select
										value={props.weddingData.wedding_information_person_2_under_18}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												wedding_information_person_2_under_18: value
											})
											//
										}}
									>
										<SelectTrigger
											id="wedding_information_person_2_under_18"
											data-testid="wedding_information_person_2_under_18"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_under_18_court_name"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Name
									</Label>
									<Input
										id="wedding_information_person_2_under_18_court_name"
										type="text"
										name="wedding_information_person_2_under_18_court_name"
										disabled={props.weddingData.wedding_information_person_2_under_18 === "No"}
										onChange={(e) => updateData({ wedding_information_person_2_under_18_court_name: e.target.value })}
										value={props.weddingData.wedding_information_person_2_under_18_court_name}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_under_18_court_location"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Location
									</Label>
									<Input
										id="wedding_information_person_2_under_18_court_location"
										type="text"
										name="wedding_information_person_2_under_18_court_location"
										disabled={props.weddingData.wedding_information_person_2_under_18 === "No"}
										onChange={(e) => updateData({ wedding_information_person_2_under_18_court_location: e.target.value })}
										value={props.weddingData.wedding_information_person_2_under_18_court_location}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_under_18_court_date"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Date
									</Label>
									<Input
										id="wedding_information_person_2_under_18_court_date"
										type="date"
										disabled={props.weddingData.wedding_information_person_2_under_18 === "No"}
										value={convertDateFieldToFieldDateFormat(props.weddingData.wedding_information_person_2_under_18_court_date)}
										onChange={(e) => updateData({ wedding_information_person_2_under_18_court_date: e.target.value })}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
									/>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_person_2_under_18_court_approval"
										className="text-base text-gray-600 font-light mt-5"
									>
										Court Approval?
									</Label>
									<Select
										disabled={props.weddingData.wedding_information_person_2_under_18 === "No"}
										value={props.weddingData.wedding_information_person_2_under_18_court_approval}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												wedding_information_person_2_under_18_court_approval: value
											})
											//
										}}
									>
										<SelectTrigger
											id="wedding_information_person_2_under_18_court_approval"
											data-testid="wedding_information_person_2_under_18_court_approval"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>

							</CardContent>
						</Card>

						<Card className="col-span-2 md:col-span-1">
							<CardHeader>
								<CardTitle className="underline text-center">Parties Related/Signatures</CardTitle>
							</CardHeader>
							<CardContent>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_parties_related"
										className="text-base text-gray-600 font-light mt-5"
									>
										Are the Parties Related?
									</Label>
									<Select
										value={props.weddingData.wedding_information_parties_related}
										onValueChange={(value) => {
											//
											updateData({
												...props.weddingData,
												wedding_information_parties_related: value
											})
											//
										}}
									>
										<SelectTrigger
											id="wedding_information_parties_related"
											data-testid="wedding_information_parties_related"
											className="w-full bg-gray-100 text-lg"
										>
											<SelectValue placeholder="Please select an option" />
										</SelectTrigger>
										<SelectContent className="text-lg">
											<SelectItem value="No" className="text-lg">No</SelectItem>
											<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
										</SelectContent>
									</Select>
								</div>
								<div className="grid w-full items-center gap-1.5">
									<Label
										htmlFor="wedding_information_relationship"
										className="text-base text-gray-600 font-light mt-5"
									>
										Whats the Relationship?
									</Label>
									<Input
										id="wedding_information_relationship"
										type="text"
										name="wedding_information_relationship"
										disabled={props.weddingData.wedding_information_parties_related === "No"}
										onChange={(e) => updateData({ wedding_information_relationship: e.target.value })}
										value={props.weddingData.wedding_information_relationship}
										className="w-full bg-gray-100 text-lg p-0 m-0 pl-2"
									/>
								</div>

							</CardContent>
						</Card>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="invoicing">
					<AccordionTrigger className="text-lg">Invoicing</AccordionTrigger>
					<AccordionContent className="grid grid-cols-1 gap-4 w-full">
						<div className="w-full">
							<Button
								className="mt-2"
								disabled={props.status === "I"}
								onClick={() => {
									//
									const newInvoice: Invoice = {
										id: 0,
										user_id: 0,
										wedding_id: props.weddingData.id,
										invoice_date: new Date(),
										invoice_amount: 0,
										//@ts-ignore
										payment_date: null
									}
									//
									updateData({
										...props.weddingData,
										invoices: [...props.weddingData.invoices, newInvoice]
									})
									//
									let updateInvoiceChanges = props.invoiceChanges
									updateInvoiceChanges[[...props.weddingData.invoices, newInvoice].length - 1] = true
									props.setInvoiceChanges(updateInvoiceChanges)
									//
								}}
							>
								Create Invoice
							</Button>
							<div className="grid w-full items-center gap-1.5 ml-1">
								<Label
									htmlFor="total_amount"
									className="text-base text-gray-600 font-light mt-5"
								>
									Total Amount
								</Label>
								<Input
									id="total_amount"
									type="number"
									name="total_amount"
									onChange={(e) => updateData({ payment_total: removeLeadingZero(e.target.value) })}
									value={props.weddingData.payment_total}
									className="bg-gray-100 text-lg p-0 m-0 pl-3 pr-2 max-w-[200px]"
								/>
							</div>
							<div className="mt-8 w-full">
								{props.weddingData.invoices.sort((a, b) => b.payment_date && a.payment_date ? new Date(a.payment_date).getTime() - new Date(b.payment_date).getTime() : b.id - a.id).map((invoice: Invoice, index) => {
									//
									return <div
										key={"invoiceRow-" + index}
										className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-8 border-2 border-gray-200 rounded-lg mt-4 pb-3"
									>
										<div className="col-span-1 mt-4 ml-2 md:ml-4">
											<h2 className="font-semibold text-xl mt-1">Invoice</h2>
										</div>
										<div className="col-span-1 text-center mt-4 mx-4 md:mr-4">
											<Button
												variant={"outline"}
												className="w-full text-lg"
												disabled={props.status === "I"}
												onClick={() => {
													//
													setCurrentInvoiceIndex(index)
													setShowDeleteDialog(true)
													//
												}}
											>
												Delete
											</Button>
										</div>
										<div className="grid items-center gap-1.5 col-span-1 mt-1 mx-4 md:mr-4">
											<Label
												htmlFor={"status-" + index}
												className="text-base text-gray-600 font-light mt-5"
											>
												Status
											</Label>
											<h2
												id={"status-" + index}
												className="text-lg mt-1 text-gray-500 font-light border-dashed border-2 rounded-lg p-1 pl-2"
											>
												{invoice.payment_date ? "Paid" : "Outstanding"}
											</h2>
										</div>
										<div className="grid items-center gap-1.5 col-span-1 mt-1 mx-4 md:mr-4">
											<Label
												htmlFor={"invoice_date-" + index}
												className="text-base text-gray-600 font-light mt-5"
											>
												Invoice Date
											</Label>
											<Input
												id={"invoice_date-" + index}
												type="date"
												value={convertDateFieldToFieldDateFormat(invoice.invoice_date)}
												onChange={(e) => {
													//
													let updateInvoiceChanges = props.invoiceChanges
													updateInvoiceChanges[index] = true
													props.setInvoiceChanges(updateInvoiceChanges)
													//
													const updatedInvoices = props.weddingData.invoices.map((inv, i) => {
														//
														if (i === index) {
															//
															return { ...inv, invoice_date: e.target.value }
															//
														}
														//
														return inv
													})
													//
													updateData({ invoices: updatedInvoices })
												}}
												className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
											/>
										</div>
										<div className="grid items-center gap-1.5 col-span-1 mt-1 mx-4 md:mr-4">
											<Label
												htmlFor={"invoice_amount-" + index}
												className="text-base text-gray-600 font-light mt-5"
											>
												Invoice Amount
											</Label>
											<Input
												id={"invoice_amount-" + index}
												name={"invoice_amount-" + index}
												type="number"
												value={invoice.invoice_amount}
												onChange={(e) => {
													//
													let updateInvoiceChanges = props.invoiceChanges
													updateInvoiceChanges[index] = true
													props.setInvoiceChanges(updateInvoiceChanges)
													//
													const updatedInvoices = props.weddingData.invoices.map((inv, i) => {
														//
														if (i === index) {
															//
															return { ...inv, invoice_amount: removeLeadingZero(e.target.value) }
															//
														}
														//
														return inv
													})
													//
													updateData({ invoices: updatedInvoices })
												}}
												className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
											/>
										</div>
										<div className="grid items-center gap-1.5 col-span-1 mt-1 mx-4 md:mr-4">
											<Label
												htmlFor={"payment_date-" + index}
												className="text-base text-gray-600 font-light mt-5"
											>
												Payment Date
											</Label>
											<Input
												id={"payment_date-" + index}
												name={"payment_date-" + index}
												type="date"
												disabled={!invoice.invoice_date || invoice.invoice_amount === 0}
												value={convertDateFieldToFieldDateFormat(invoice.payment_date)}
												onChange={(e) => {
													//
													let updateInvoiceChanges = props.invoiceChanges
													updateInvoiceChanges[index] = true
													props.setInvoiceChanges(updateInvoiceChanges)
													//
													const updatedInvoices = props.weddingData.invoices.map((inv, i) => {
														//
														if (i === index) {
															//
															return { ...inv, payment_date: e.target.value }
															//
														}
														//
														return inv
													})
													//
													updateData({ invoices: updatedInvoices })
												}}
												className="w-full bg-gray-100 text-lg p-0 m-0 pl-2 align-middle block pr-3"
											/>
										</div>
										<div className="col-span-1 text-center mt-6 mx-4 md:mr-4">
											<div
												className="w-full text-lg"
												hidden={props.invoiceChanges[index]}
											>
												<Link
													to={"http://localhost:3333/forms/invoice/" + props.weddingData.id + "/" + invoice.id + "?token=" + token}
													target="_target"
													className="flex w-full text-lg text-white bg-blue-600 rounded-lg p-2 justify-center hover:underline hover:bg-blue-700"
												>
													Generate Invoice
												</Link>
											</div>
										</div>
										<div className="col-span-1 text-center mt-6 mx-4 md:mr-4">
											<div
												className="w-full text-lg"
												hidden={props.invoiceChanges[index]}
											>
												<Link
													to={"http://localhost:3333/forms/receipt/" + props.weddingData.id + "/" + invoice.id + "?token=" + token}
													target="_target"
													className="flex w-full text-lg text-white bg-blue-600 rounded-lg p-2 justify-center hover:underline hover:bg-blue-700"
												>
													Generate Receipt
												</Link>
											</div>
										</div>
										<div
											className="col-span-1 md:col-span-2 mt-4 text-center text-red-500"
											hidden={!props.invoiceChanges[index]}
										>
											This invoice has just been {invoice.id === 0 ? "created" : "changed"}. Update the wedding to generate an invoice or receipt.
										</div>
									</div>
								})}
							</div>
						</div>
					</AccordionContent>
				</AccordionItem>
				<ConfirmDialog openConfirm={showDeleteDialog} getResult={(result: boolean) => {
					//
					if (result) {
						//
						const updatedInvoices = props.weddingData.invoices.filter((inv, i) => i !== currentInvoiceIndex)
						updateData({ invoices: updatedInvoices })
						//
						let updatedInvoiceChanges = props.invoiceChanges.filter((inv, i) => i !== currentInvoiceIndex)
						props.setInvoiceChanges(updatedInvoiceChanges)
						//
					}
					//
					setShowDeleteDialog(false)
					//
				}} />
			</Accordion>
		)
	}
}

export function ConfirmDialog(props: { openConfirm: boolean, getResult: any }) {
	return (
		<AlertDialog
			defaultOpen={false}
			open={props.openConfirm}
		>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Delete Invoice</AlertDialogTitle>
					<AlertDialogDescription className="text-lg my-8">
						Are you sure you want to delete this invoice?
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel
						className="text-lg bg-destructive hover:bg-red-400 text-white hover:text-white"
						onClick={() => {
							props.getResult(true)
						}}
					>
						Yes
					</AlertDialogCancel>
					<AlertDialogAction
						className="text-lg"
						onClick={() => {
							props.getResult(false)
						}}
					>
						No
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
