import { useState } from "react"
import {
	useNavigate,
} from "react-router-dom";
import { RegisterUser } from "../../lib/backend/auth";
import { setToken } from "../../lib/token";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button"
import { UserRoundPlus } from "lucide-react"

export default function RegisterPage() {
	const [formDataEmail, setFormDataEmail] = useState("")
	const [formDataPassword, setFormDataPassword] = useState("")
	const [formDataPasswordConfirm, setFormDataPasswordConfirm] = useState("")
	const [formDataFirstName, setFormDataFirstName] = useState("")
	const [formDataLastName, setFormDataLastName] = useState("")
	const [formDataError, setFormDataError] = useState(false)
	const [formDataErrorText, setFormDataErrorText] = useState("")

	const navigate = useNavigate();

	async function formSubmit(event: any) {
		event.preventDefault()

		let result = false

		if (formDataPassword !== formDataPasswordConfirm) {
			setFormDataError(true)
			setFormDataErrorText("Password and Password Confirm must be a match")
		} else if (formDataPassword.length < 6) {
			setFormDataError(true)
			setFormDataErrorText("Password must be 6 characters or more")
		} else {
			result = true
			setFormDataError(false)
			setFormDataErrorText("")
		}

		if (result) {
			const response = await RegisterUser(formDataEmail, formDataPassword, formDataPasswordConfirm, formDataFirstName, formDataLastName)
			if (response.ok) {
				const body = await response.json()
				setToken(body.token)
				navigate("/home/weddings")
				// })
			} else {
				const body = await response.json()
				setFormDataError(true)
				if (body.error) {
					setFormDataErrorText(body.error)
				} else {
					setFormDataErrorText("Something has gone wrong. Please try again.")
				}
			}
		}
	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-24 w-auto"
						src="/logo.png"
						alt="Celebrant Briefcase"
					/>
					<h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
						Register a new account
					</h2>
					<p className="mt-1 text-center text-md leading-8 font-semibold">
						Free 2 week trial
					</p>
				</div>

				<div hidden={!formDataError} className="mx-auto text-red-500 mt-8 text-xl">
					{formDataErrorText}
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={formSubmit}>
						<div>
							<label htmlFor="FirstName" className="block text-sm font-medium leading-6 text-gray-900">
								First Name
							</label>
							<div className="mt-2">
								<Input
									id="FirstName"
									name="FirstName"
									type="text"
									autoComplete="FirstName"
									required
									placeholder="First name"
									onChange={(e) => setFormDataFirstName(e.target.value)}
									value={formDataFirstName}
								/>
							</div>
						</div>
						<div>
							<label htmlFor="LastName" className="block text-sm font-medium leading-6 text-gray-900">
								Last Name
							</label>
							<div className="mt-2">
								<Input
									id="LastName"
									name="LastName"
									type="text"
									autoComplete="LastName"
									required
									placeholder="Last name"
									onChange={(e) => setFormDataLastName(e.target.value)}
									value={formDataLastName}
								/>
							</div>
						</div>
						<div>
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
								Email Address
							</label>
							<div className="mt-2">
								<Input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									placeholder="Email address"
									onChange={(e) => setFormDataEmail(e.target.value)}
									value={formDataEmail}
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
									Password (Minimum of 6 characters)
								</label>
							</div>
							<div className="mt-2">
								<Input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									placeholder="Password"
									onChange={(e) => setFormDataPassword(e.target.value)}
									value={formDataPassword}
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="passwordConfirm" className="block text-sm font-medium leading-6 text-gray-900">
									Password Confirm
								</label>
							</div>
							<div className="mt-2">
								<Input
									id="passwordConfirm"
									name="passwordConfirm"
									type="password"
									autoComplete="current-password-confirm"
									required
									placeholder="Password confirm"
									onChange={(e) => {
										setFormDataPasswordConfirm(e.target.value)
									}}
									value={formDataPasswordConfirm}
								/>
							</div>
						</div>

						<div>
							<Button
								type="submit"
								className="w-full"
							>
								<UserRoundPlus className="mr-2 h-4 w-4" /> Register New Account
							</Button>
						</div>
					</form>

				</div>
			</div>
		</>
	)
}
