import { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { UserGetMe, UserShow } from "../../lib/backend/user"
import { Button } from "../../components/ui/button"
import { DataTable } from "../../components/TemplateTable"
import { Rows, X } from "lucide-react"

import { ArrowUpDown, MoreHorizontal } from "lucide-react"

import {
	Dialog,
	DialogClose,
	DialogFooter,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../components/ui/dialog"

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "../../components/ui/alert-dialog"

import { Printer } from "../../../../backend/models/printer"
import { PrinterCreate, PrinterIndex, PrinterShow, PrinterUpdate } from "../../lib/backend/printer"
import { Label } from "../../components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card"
import { Textarea } from "../../components/ui/textarea"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion"
import { Input } from "../../components/ui/input"
import { GetSession } from "../../lib/backend/auth"
import { User } from "../../../../backend/models/user"
import { PropertyKeys } from "@ag-grid-community/core"
import { InactiveMessage } from "../../components/InactiveMessage"

type PrinterTableRow = Pick<Printer, "id" | "printer_name" | "default_printer">

export default function Form15PrintersPage() {

	const [initialLoading, setInitialLoading] = useState(true)
	const [rows, setRows] = useState<PrinterTableRow[]>([])
	const [currentRow, setCurrentRow] = useState<Printer>(defaultRow)
	const [currentRowOriginal, setCurrentRowOriginal] = useState<Printer>(defaultRow)
	const [showModal, setShowModal] = useState(false)
	const [create, setCreate] = useState(false)
	const [loading, setLoading] = useState(true)
	const [blockDefault, setBlockDefault] = useState(false)
	const [user, setUser] = useState<{ id: number, manager: User["manager"], status: User["status"] }>({ id: 0, manager: "", status: "A" })

	const navigate = useNavigate();

	useEffect(() => {
		getFilesData()
	}, [])

	async function getFilesData() {
		//
		const myPrintersResponse = await PrinterIndex()
		if (myPrintersResponse.ok) {
			//
			const myPrintersBody = await myPrintersResponse.json()
			// const myPrintersBodyClean = myPrintersBody.map((printer: Printer) => {
			// 	if (printer.printer_information_event_details_marriage_date) {
			// 		///@ts-ignore
			// 		printer.printer_date_string = dateToNiceString(new Date(printer.printer_information_event_details_marriage_date))
			// 	} else {
			// 		///@ts-ignore
			// 		printer.printer_date_string = ""
			// 	}
			// 	return printer
			// })
			setRows(myPrintersBody)
			//
			const response = await GetSession()
			if (!response.ok) {
				navigate("/")
			} else {
				const body = await response.json()
				const meResponse = await UserShow(body.session.id)
				if (meResponse.ok) {
					const meBody = await meResponse.json()
					setUser(meBody)
					setInitialLoading(false)
				}
			}
			//
		} else {
			//
			navigate("/")
			//
		}
		//
	}

	async function onNewClick() {
		//
		setCurrentRow(defaultRow)
		setCurrentRowOriginal(defaultRow)
		setBlockDefault(false)
		setCreate(true)
		setLoading(false)
		setShowModal(true)
		//
	}

	async function onRowClick(id: number) {
		//
		setLoading(true)
		setCurrentRow(defaultRow)
		setCurrentRowOriginal(defaultRow)
		setCreate(false)
		setShowModal(true)
		//
		const selectedRow = rows.find((row) => row.id === id)
		if (selectedRow) {
			const printerResponse = await PrinterShow(id)
			if (printerResponse.ok) {
				const printerBody = await printerResponse.json()
				if (printerBody.default_printer === "Yes") {
					setBlockDefault(true)
				} else {
					setBlockDefault(false)
				}
				setCurrentRow(printerBody)
				setCurrentRowOriginal(printerBody)
				setLoading(false)
			}
		}
		//
	}

	return (
		<div className="px-2 mt-4">
			<div>
				<div className="text-2xl font-semibold mb-4 flex justify-between">
					<h1 className="text-3xl font-bold">
						Printers
					</h1>
				</div>
				<div className="w-20 h-20 mt-8" hidden={!initialLoading}>
					<svg aria-hidden="true" className="w-full text-left h-full m-0 mt-6 mb-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
				<div hidden={initialLoading} className="mt-8 w-full">
					<InactiveMessage />
					<DataTable
						columns={[
							{ field: "printer_name", headerName: "Printer Name" },
							{ field: "default_printer", headerName: "Default Printer" },
						]}
						data={rows}
						columnVisibility={{
							printer_name: true,
							default_printer: true,
						}}
						onNewClick={onNewClick}
						onRowClick={onRowClick}
						createButtonText="Create Printer"
						disableCreateButton={user.status === "I"}
					/>
				</div>
				<PrinterDialog
					openParam={showModal}
					setOpen={setShowModal}
					create={create}
					printerData={currentRow}
					originalRow={currentRowOriginal}
					setPrinterData={setCurrentRow}
					loading={loading}
					setRows={setRows}
					rows={rows}
					blockDefault={blockDefault}
					status={user.status}
				/>
			</div>
		</div>
	)
}

export function PrinterDialog(props: {
	openParam: boolean,
	setOpen: any,
	create: boolean,
	printerData: Printer,
	originalRow: Printer,
	setPrinterData: Function,
	loading: boolean,
	rows: PrinterTableRow[],
	setRows: Function,
	blockDefault: boolean,
	status: User["status"]
}) {

	const [openConfirm, setOpenConfirm] = useState(false)

	async function tryToClose() {
		if (JSON.stringify(props.printerData) !== JSON.stringify(props.originalRow)) {
			setOpenConfirm(true)
		} else {
			props.setOpen(false)
		}
	}

	async function getResult(result: boolean) {
		setOpenConfirm(false)
		if (result) {
			props.setOpen(false)
		}
	}

	async function submitData() {
		//
		const printerData = props.printerData
		if (props.create) {
			//
			const createResponse = await PrinterCreate(printerData)
			if (createResponse.ok) {
				//
				const createBody = await createResponse.json()
				let newRows = JSON.parse(JSON.stringify(props.rows))
				// if (createBody.wedding_information_event_details_marriage_date) {
				// 	///@ts-ignore
				// 	createBody.wedding_date_string = dateToNiceString(new Date(createBody.wedding_information_event_details_marriage_date))
				// } else {
				// 	///@ts-ignore
				// 	createBody.wedding_date_string = ""
				// }
				//
				newRows = newRows.map((row: PrinterTableRow) => {
					//
					if (createBody.default_printer === "Yes") {
						row.default_printer = "No"
					}
					//
					return row
					//
				})
				newRows.push(createBody)
				//
				props.setRows(newRows)
				props.setOpen(false)
				//
			}
			//
		} else {
			//
			const updateResponse = await PrinterUpdate(printerData.id, printerData)
			if (updateResponse.ok) {
				//
				const updateBody = await updateResponse.json()
				const updatedRows = props.rows.map(row => {
					if (row.id === updateBody.id) {
						return updateBody
					} else if (updateBody.default_printer === "Yes") {
						row.default_printer = "No"
					}
					return row
				})
				props.setRows(updatedRows)
				props.setOpen(false)
				//
			}
			//
		}
		//

	}

	//
	return (
		<>
			<Dialog
				defaultOpen={true}
				open={props.openParam}
				// onOpenChange={() => {
				// 	console.log("Trying to open/close")
				// }}
				modal={true}
			>
				<DialogContent className="">
					<DialogHeader>
						<DialogTitle>
							<div className="flex flex-row justify-between align-middle w-full h-10">
								<h2 className="text-3xl -mt-1">
									{props.create ? "Create Printer" : "Maintain Printer"}
								</h2>
								<Button
									className="-m-2 px-2 outline-none ring-0 ring-transparent ring-white border-0 text-gray-600"
									variant={"outline"}
									onClick={() => {
										tryToClose()
									}}
								>
									<X />
								</Button>
							</div>
						</DialogTitle>
					</DialogHeader>
					<div
						className="-mt-1 mb-14 sm:mb-1 overflow-y-auto"
						style={{ height: "70vh" }}
						hidden={props.loading}
					>
						<PrinterForm
							printerData={props.printerData}
							setPrinterData={props.setPrinterData}
							blockDefault={props.blockDefault}
							status={props.status}
						/>
					</div>
					<div className="w-full h-full" hidden={!props.loading}>
						<svg aria-hidden="true" className="w-full text-center h-20 m-0 mt-6 mb-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
							<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
						</svg>
					</div>
					<div hidden={props.loading} className="h-8">
						<DialogFooter className="h-full">
							{/* <DialogClose asChild={true}> */}
							<Button
								variant={"outline"}
								type="button"
								className="text-lg"
								data-testid="closePrinter"
								onClick={() => {
									tryToClose()
								}}
							>
								Close
							</Button>
							{/* </DialogClose> */}
							{/* <DialogClose asChild={false}> */}
							<Button
								variant={"default"}
								type="submit"
								className="text-lg"
								data-testid="createUpdatePrinter"
								onClick={submitData}
								disabled={JSON.stringify(props.printerData) === JSON.stringify(props.originalRow) || props.status === "I"}
							>
								{props.create ? "Create Printer" : "Update Printer"}
							</Button>
							{/* </DialogClose> */}
						</DialogFooter>
					</div>
				</DialogContent>
			</Dialog>
			<ConfirmDialog openConfirm={openConfirm} create={props.create} getResult={getResult} />
		</>
	)
}

export function ConfirmDialog(props: { openConfirm: boolean, create: boolean, getResult: any }) {
	return (
		<AlertDialog
			defaultOpen={false}
			open={props.openConfirm}
		>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
					<AlertDialogDescription className="text-lg my-8">
						You have made changes to this printer.
						<br />
						Are you sure you want to close without saving and lose all your changes?
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel
						className="text-lg bg-destructive hover:bg-red-400 text-white hover:text-white"
						onClick={() => {
							props.getResult(true)
						}}
					>
						Yes. Remove my changes.
					</AlertDialogCancel>
					<AlertDialogAction
						className="text-lg"
						onClick={() => {
							props.getResult(false)
						}}
					>
						No. Take me back to the printer.
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export function PrinterForm(props: {
	printerData: Printer,
	setPrinterData: Function,
	blockDefault: boolean,
	status: User["status"]
}) {
	//
	function updateData(newValue: any) {
		if (props.status !== "I") {
			props.setPrinterData({ ...props.printerData, ...newValue })
		}
	}
	return (
		<div className="p-4 ">
			<div className="grid grid-cols-2 gap-4">
				<Card className="col-span-2 lg:col-span-1">
					<CardHeader>
						<CardTitle>General</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="printer_name"
								className="text-base text-gray-600 font-light"
							>
								Printer Name
							</Label>
							<Input
								id="printer_name"
								type="text"
								name="printer_name"
								onChange={(e) => updateData({ ...props.printerData, printer_name: e.target.value })}
								value={props.printerData.printer_name}
								className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
								autoFocus
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="default_printer"
								className="text-base text-gray-600 font-light mt-5"
							>
								Current Printer?
							</Label>
							<Select
								name="default_printer"
								value={props.printerData.default_printer}
								disabled={props.blockDefault}
								onValueChange={(value) => updateData({ ...props.printerData, default_printer: value })}
							>
								<SelectTrigger
									id="default_printer"
									data-testid="default_printer"
									className="w-full bg-gray-200 text-lg"
									name="default_printer"
								>
									<SelectValue placeholder="No" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="No" className="text-lg">No</SelectItem>
									<SelectItem value="Yes" className="text-lg">Yes</SelectItem>
								</SelectContent>
							</Select>
							<div className="text-sm text-gray-600 font-light">There can only be one current printer</div>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="font_size"
								className="text-base text-gray-600 font-light mt-5"
							>
								Font Size
							</Label>
							<Select
								name="font_size"
								value={props.printerData.font_size}
								onValueChange={(value) => updateData({ ...props.printerData, font_size: value })}
							>
								<SelectTrigger
									id="font_size"
									className="w-full bg-gray-200 text-lg"
									name="font_size"
								>
									<SelectValue placeholder="No" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="8" className="text-lg">8</SelectItem>
									<SelectItem value="9" className="text-lg">9</SelectItem>
									<SelectItem value="10" className="text-lg">10</SelectItem>
									<SelectItem value="11" className="text-lg">11</SelectItem>
									<SelectItem value="12" className="text-lg">12</SelectItem>
									<SelectItem value="13" className="text-lg">13</SelectItem>
									<SelectItem value="14" className="text-lg">14</SelectItem>
									<SelectItem value="15" className="text-lg">15</SelectItem>
									<SelectItem value="16" className="text-lg">16</SelectItem>
									<SelectItem value="17" className="text-lg">17</SelectItem>
									<SelectItem value="18" className="text-lg">18</SelectItem>
									<SelectItem value="19" className="text-lg">19</SelectItem>
									<SelectItem value="20" className="text-lg">20</SelectItem>
									<SelectItem value="21" className="text-lg">21</SelectItem>
									<SelectItem value="22" className="text-lg">22</SelectItem>
									<SelectItem value="23" className="text-lg">23</SelectItem>
									<SelectItem value="24" className="text-lg">24</SelectItem>
									<SelectItem value="25" className="text-lg">25</SelectItem>
									<SelectItem value="26" className="text-lg">26</SelectItem>
									<SelectItem value="27" className="text-lg">27</SelectItem>
									<SelectItem value="28" className="text-lg">28</SelectItem>
									<SelectItem value="29" className="text-lg">29</SelectItem>
									<SelectItem value="30" className="text-lg">30</SelectItem>
								</SelectContent>
							</Select>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								htmlFor="font_colour"
								className="text-base text-gray-600 font-light mt-5"
							>
								Font Colour
							</Label>
							<Input
								id="font_colour"
								type="color"
								name="font_colour"
								onChange={(e) => updateData({ ...props.printerData, font_colour: e.target.value })}
								value={props.printerData.font_colour}
								className="w-full bg-gray-200 text-lg p-0 m-0 pl-2 ring-offset-0"
							/>
						</div>
						<div className="grid w-full items-center gap-1.5">
							<Label
								className="text-base text-gray-600 font-light mt-5"
							>
								Font Family
							</Label>
							<fieldset>
								{fontList.map((font, index) => {
									return (
										<div key={index} className="w-full mt-3">
											<input
												id={font.fontName}
												type="radio"
												value={font.fontName}
												checked={props.printerData.font_name === font.fontName}
												name="FontFamily"
												onClick={(e) => updateData({ ...props.printerData, font_name: font.fontName })}
												onChange={(e) => updateData({ ...props.printerData, font_name: font.fontName })}
											/>
											<label
												htmlFor={font.fontName}
												style={{ fontFamily: font.fontFamily }}
												className="ml-4"
											>
												The quick brown fox jumps over the lazy dog
											</label>
										</div>
									)
								})}
							</fieldset>
						</div>
					</CardContent>
				</Card>
				<Card className="col-span-2 lg:col-span-1">
					<CardHeader>
						<CardTitle>Position Elements</CardTitle>
					</CardHeader>
					<CardContent>
						<div className="grid grid-cols-2 gap-4">
							<div className="grid w-full items-center gap-1.5 col-span-1">
								<Label
									htmlFor="celebrant_horizontal"
									className="text-base text-gray-600 font-light text-center"
								>
									Celebrant Horizontal
								</Label>
								<PositionAdjust
									name="celebrant_horizontal"
									add={() => {
										updateData({ ...props.printerData, celebrant_horizontal: props.printerData.celebrant_horizontal + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, celebrant_horizontal: props.printerData.celebrant_horizontal - 1 })
									}}
									value={props.printerData.celebrant_horizontal}
									changed={(v: any) => updateData({ ...props.printerData, celebrant_horizontal: parseInt(v) })}
								/>
							</div>
							<div className="grid w-full items-center gap-1.5 col-span-1 text-center">
								<Label
									htmlFor="celebrant_vertical"
									className="text-base text-gray-600 font-light"
								>
									Celebrant Vertical
								</Label>
								<PositionAdjust
									name="celebrant_vertical"
									add={() => {
										updateData({ ...props.printerData, celebrant_vertical: props.printerData.celebrant_vertical + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, celebrant_vertical: props.printerData.celebrant_vertical - 1 })
									}}
									value={props.printerData.celebrant_vertical}
									changed={(v: any) => updateData({ ...props.printerData, celebrant_vertical: parseInt(v) })}
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-4 mt-5">
							<div className="grid w-full items-center gap-1.5 col-span-1">
								<Label
									htmlFor="location_horizontal"
									className="text-base text-gray-600 font-light text-center"
								>
									Location Horizontal
								</Label>
								<PositionAdjust
									name="location_horizontal"
									add={() => {
										updateData({ ...props.printerData, location_horizontal: props.printerData.location_horizontal + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, location_horizontal: props.printerData.location_horizontal - 1 })
									}}
									value={props.printerData.location_horizontal}
									changed={(v: any) => updateData({ ...props.printerData, location_horizontal: parseInt(v) })}
								/>
							</div>
							<div className="grid w-full items-center gap-1.5 col-span-1 text-center">
								<Label
									htmlFor="location_vertical"
									className="text-base text-gray-600 font-light"
								>
									Location Vertical
								</Label>
								<PositionAdjust
									name="location_vertical"
									add={() => {
										updateData({ ...props.printerData, location_vertical: props.printerData.location_vertical + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, location_vertical: props.printerData.location_vertical - 1 })
									}}
									value={props.printerData.location_vertical}
									changed={(v: any) => updateData({ ...props.printerData, location_vertical: parseInt(v) })}
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-4 mt-5">
							<div className="grid w-full items-center gap-1.5 col-span-1">
								<Label
									htmlFor="groom_horizontal"
									className="text-base text-gray-600 font-light text-center"
								>
									Party 1 Horizontal
								</Label>
								<PositionAdjust
									name="groom_horizontal"
									add={() => {
										updateData({ ...props.printerData, groom_horizontal: props.printerData.groom_horizontal + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, groom_horizontal: props.printerData.groom_horizontal - 1 })
									}}
									value={props.printerData.groom_horizontal}
									changed={(v: any) => updateData({ ...props.printerData, groom_horizontal: parseInt(v) })}
								/>
							</div>
							<div className="grid w-full items-center gap-1.5 col-span-1 text-center">
								<Label
									htmlFor="groom_vertical"
									className="text-base text-gray-600 font-light"
								>
									Party 1 Vertical
								</Label>
								<PositionAdjust
									name="groom_vertical"
									add={() => {
										updateData({ ...props.printerData, groom_vertical: props.printerData.groom_vertical + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, groom_vertical: props.printerData.groom_vertical - 1 })
									}}
									value={props.printerData.groom_vertical}
									changed={(v: any) => updateData({ ...props.printerData, groom_vertical: parseInt(v) })}
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-4 mt-5">
							<div className="grid w-full items-center gap-1.5 col-span-1">
								<Label
									htmlFor="bride_horizontal"
									className="text-base text-gray-600 font-light text-center"
								>
									Party 2 Horizontal
								</Label>
								<PositionAdjust
									name="bride_horizontal"
									add={() => {
										updateData({ ...props.printerData, bride_horizontal: props.printerData.bride_horizontal + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, bride_horizontal: props.printerData.bride_horizontal - 1 })
									}}
									value={props.printerData.bride_horizontal}
									changed={(v: any) => updateData({ ...props.printerData, bride_horizontal: parseInt(v) })}
								/>
							</div>
							<div className="grid w-full items-center gap-1.5 col-span-1 text-center">
								<Label
									htmlFor="bride_vertical"
									className="text-base text-gray-600 font-light"
								>
									Party 2 Vertical
								</Label>
								<PositionAdjust
									name="bride_vertical"
									add={() => {
										updateData({ ...props.printerData, bride_vertical: props.printerData.bride_vertical + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, bride_vertical: props.printerData.bride_vertical - 1 })
									}}
									value={props.printerData.bride_vertical}
									changed={(v: any) => updateData({ ...props.printerData, bride_vertical: parseInt(v) })}
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-4 mt-5">
							<div className="grid w-full items-center gap-1.5 col-span-1">
								<Label
									htmlFor="day_horizontal"
									className="text-base text-gray-600 font-light text-center"
								>
									Day Horizontal
								</Label>
								<PositionAdjust
									name="day_horizontal"
									add={() => {
										updateData({ ...props.printerData, day_horizontal: props.printerData.day_horizontal + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, day_horizontal: props.printerData.day_horizontal - 1 })
									}}
									value={props.printerData.day_horizontal}
									changed={(v: any) => updateData({ ...props.printerData, day_horizontal: parseInt(v) })}
								/>
							</div>
							<div className="grid w-full items-center gap-1.5 col-span-1 text-center">
								<Label
									htmlFor="day_vertical"
									className="text-base text-gray-600 font-light"
								>
									Day Vertical
								</Label>
								<PositionAdjust
									name="day_vertical"
									add={() => {
										updateData({ ...props.printerData, day_vertical: props.printerData.day_vertical + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, day_vertical: props.printerData.day_vertical - 1 })
									}}
									value={props.printerData.day_vertical}
									changed={(v: any) => updateData({ ...props.printerData, day_vertical: parseInt(v) })}
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-4 mt-5">
							<div className="grid w-full items-center gap-1.5 col-span-1">
								<Label
									htmlFor="month_horizontal"
									className="text-base text-gray-600 font-light text-center"
								>
									Month Horizontal
								</Label>
								<PositionAdjust
									name="month_horizontal"
									add={() => {
										updateData({ ...props.printerData, month_horizontal: props.printerData.month_horizontal + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, month_horizontal: props.printerData.month_horizontal - 1 })
									}}
									value={props.printerData.month_horizontal}
									changed={(v: any) => updateData({ ...props.printerData, month_horizontal: parseInt(v) })}
								/>
							</div>
							<div className="grid w-full items-center gap-1.5 col-span-1 text-center">
								<Label
									htmlFor="month_vertical"
									className="text-base text-gray-600 font-light"
								>
									Month Vertical
								</Label>
								<PositionAdjust
									name="month_vertical"
									add={() => {
										updateData({ ...props.printerData, month_vertical: props.printerData.month_vertical + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, month_vertical: props.printerData.month_vertical - 1 })
									}}
									value={props.printerData.month_vertical}
									changed={(v: any) => updateData({ ...props.printerData, month_vertical: parseInt(v) })}
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 gap-4 mt-5">
							<div className="grid w-full items-center gap-1.5 col-span-1">
								<Label
									htmlFor="year_horizontal"
									className="text-base text-gray-600 font-light text-center"
								>
									Year Horizontal
								</Label>
								<PositionAdjust
									name="year_horizontal"
									add={() => {
										updateData({ ...props.printerData, year_horizontal: props.printerData.year_horizontal + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, year_horizontal: props.printerData.year_horizontal - 1 })
									}}
									value={props.printerData.year_horizontal}
									changed={(v: any) => updateData({ ...props.printerData, year_horizontal: parseInt(v) })}
								/>
							</div>
							<div className="grid w-full items-center gap-1.5 col-span-1 text-center">
								<Label
									htmlFor="year_vertical"
									className="text-base text-gray-600 font-light"
								>
									Year Vertical
								</Label>
								<PositionAdjust
									name="year_vertical"
									add={() => {
										updateData({ ...props.printerData, year_vertical: props.printerData.year_vertical + 1 })
									}}
									minus={() => {
										updateData({ ...props.printerData, year_vertical: props.printerData.year_vertical - 1 })
									}}
									value={props.printerData.year_vertical}
									changed={(v: any) => updateData({ ...props.printerData, year_vertical: parseInt(v) })}
								/>
							</div>
						</div>
					</CardContent>
				</Card>
			</div>
		</div>
	)
}

function PositionAdjust(props: {
	name: string,
	add: any
	minus: any
	value: number
	changed: any
}) {

	return (
		<div className="w-full flex rounded-lg bg-gray-300 px-3 py-2">
			<Button
				onClick={props.minus}
				className="text-lg"
			>
				-
			</Button>
			<Input
				name={props.name}
				className="text-lg text-center w-full mx-3"
				value={props.value}
				type="number"
				onChange={(e) => props.changed(e.target.value)}
			/>
			<Button
				onClick={props.add}
				className="text-lg"
			>
				+
			</Button>
		</div>
	)
}

const defaultRow: Printer = {

	id: 0,

	user_id: 0,

	printer_name: "",

	default_printer: "No",

	font_colour: "#000000",
	font_name: "Arial",
	font_size: "12",

	bride_horizontal: 0,
	bride_vertical: 0,

	celebrant_horizontal: 0,
	celebrant_vertical: 0,

	groom_horizontal: 0,
	groom_vertical: 0,

	location_horizontal: 0,
	location_vertical: 0,

	day_horizontal: 0,
	day_vertical: 0,
	month_horizontal: 0,
	month_vertical: 0,
	year_horizontal: 0,
	year_vertical: 0,

}

var fontList = [{
	fontName: "Aargau Bold",
	fontFamily: "Aargau_Bold"
}, {
	fontName: "Abuela",
	fontFamily: "Abuela"
}, {
	fontName: "AccoladeSerial Bold",
	fontFamily: "AccoladeSerial_Bold"
}, {
	fontName: "Apple Chancery",
	fontFamily: "Apple_Chancery"
}, {
	fontName: "Arial",
	fontFamily: "Arial"
}, {
	fontName: "Black Tuesday Italic",
	fontFamily: "Black_Tuesday_Italic"
}, {
	fontName: "Courier PS Bold",
	fontFamily: "Courier_PS_Bold"
}, {
	fontName: "Courier PS BoldItalic",
	fontFamily: "Courier_PS_BoldItalic"
}, {
	fontName: "Cuida",
	fontFamily: "Cuida"
}, {
	fontName: "Curacao Regular",
	fontFamily: "Curacao_Regular"
}, {
	fontName: "D690 Roman Regular",
	fontFamily: "D690_Roman_Regular"
}, {
	fontName: "Deco Regular",
	fontFamily: "Deco_Regular"
}, {
	fontName: "Dekka Dense JL",
	fontFamily: "Dekka_Dense_JL"
}, {
	fontName: "EdwardianScript",
	fontFamily: "EdwardianScript"
}, {
	fontName: "Enigma Scrawl 4 BRK",
	fontFamily: "Enigma_Scrawl_4_BRK"
}, {
	fontName: "MelbourneSerial Light Regular",
	fontFamily: "MelbourneSerial_Light_Regular"
}, {
	fontName: "MercedesSerial Light Regular",
	fontFamily: "MercedesSerial_Light_Regular"
}, {
	fontName: "Merida",
	fontFamily: "Merida"
}, {
	fontName: "Mf Wedding Bells",
	fontFamily: "Mf_Wedding_Bells"
}, {
	fontName: "Pneumatics Wide BRK",
	fontFamily: "Pneumatics_Wide_BRK"
}, {
	fontName: "Prestige Regular",
	fontFamily: "Prestige_Regular"
}, {
	fontName: "PriamosSerial Xbold Regular",
	fontFamily: "PriamosSerial_Xbold_Regular"
}, {
	fontName: "PriamosSerial Xlight Regular",
	fontFamily: "PriamosSerial_Xlight_Regular"
}, {
	fontName: "PrimerPrint Bold",
	fontFamily: "PrimerPrint_Bold"
}, {
	fontName: "Regra",
	fontFamily: "Regra"
}, {
	fontName: "ReportRg Regular",
	fontFamily: "ReportRg_Regular"
}, {
	fontName: "RiccioneSerial Italic",
	fontFamily: "RiccioneSerial_Italic"
}, {
	fontName: "RiccioneSerial Light Regular",
	fontFamily: "RiccioneSerial_Light Regular"
}, {
	fontName: "Roman Regular",
	fontFamily: "Roman_Regular"
}, {
	fontName: "SF Cartoonist Hand",
	fontFamily: "SF_Cartoonist_Hand"
}, {
	fontName: "SF Cartoonist Hand Bold",
	fontFamily: "SF_Cartoonist_Hand_Bold"
}, {
	fontName: "Sans Bold",
	fontFamily: "Sans_Bold"
}]
