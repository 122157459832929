import { FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function WeddingIndex() {
	return FetchRequestGET(`/weddings`)
}

export async function WeddingShow(id: number) {
	return FetchRequestGET(`/weddings/${id}`)
}

export async function WeddingShowPublic(id: number) {
	return FetchRequestGET(`/weddings-public/${id}`)
}

export async function WeddingUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/weddings/${id}`,
		body
	)
}

export async function WeddingUpdatePublic(id: number, body: any) {
	return FetchRequestPATCH(
		`/weddings-public/${id}`,
		body
	)
}

export async function WeddingCreate(body: any) {
	return FetchRequestPOST(
		`/weddings`,
		body
	)
}
