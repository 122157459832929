import { FetchRequestPOST, FetchRequestGET, FetchRequestDELETE, FetchRequestPATCH } from "../api"

export async function RegisterUser(email: string, password: string, password_confirmation: string, first_name: string, last_name: string) {
	return FetchRequestPOST(
		'/api/auth/signup',
		{ email, password, password_confirmation, first_name, last_name }
	)
}

export async function LoginUser(email: string, password: string) {
	return FetchRequestPOST(
		'/api/auth/login',
		{ email, password }
	)
}

export async function LogoutUser() {
	return FetchRequestDELETE('/api/auth/logout')
}

export async function GetSession() {
	return FetchRequestGET(
		"/sessions/my_session"
	)
}

export async function UpdatePassword(new_password: string, sid: string) {
	return FetchRequestPOST(
		`/api/auth/change-password`,
		{ new_password: new_password, sid: sid }
	)
}

export async function VerifyEmail(sid: string) {
	return FetchRequestGET(
		`/identity/email_verification?sid=${sid}`
	)
}

export async function PasswordReset(email: string) {
	return FetchRequestPOST(
		`/api/auth/forgot-password`,
		{ email: email }
	)
}

export async function ChangePasswordUpdate(body: any) {
	return FetchRequestPATCH(
		`/users/change-password`,
		body
	)
}

