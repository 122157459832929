import { useEffect, useState } from "react";
import { UserGetMe, UserUpdateMe } from "../../lib/backend/user";
import { User } from "../../../../backend/models/user";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast"
import { FeedbackCreate } from "../../lib/backend/feedback";
import { Link } from "react-router-dom";

export default function SettingsPage() {

	const [data, setData] = useState<any>("")
	const [submitted, setSubmitted] = useState<any>(false)
	const { toast } = useToast()

	async function submitFeedback() {
		//
		const response = await FeedbackCreate({ feedback: data })
		if (response.ok) {
			//
			setSubmitted(true)
			//
			// toast({
			// 	// title: "Settings Saved",
			// 	duration: 1500,
			// 	description: <div className=" h-auto w-full text-xl text-green-600 font-bold">Your feedback has been sent</div>,
			// })
			//
		} else {
			//
			toast({
				// title: "Settings Saved",
				duration: 1500,
				description: <div className=" h-auto w-full text-xl text-red-600 font-bold">Your feedback could not be sent</div>,
			})
			//
		}
		//
	}

	return (
		<div className="px-2 mt-4">
			<div>
				<div className="text-2xl font-semibold mb-4 flex justify-between">
					<h1 className="text-3xl font-bold">
						Feedback
					</h1>
				</div>
				{submitted === true && <div className="grid grid-cols-1 max-w-2xl mt-10 text-lg text-gray-600">
					<p>
						Thank you for your feedback!
					</p>
					<p className="mt-5">
						Ill respond as soon as I can.
					</p>
					<p className="mt-5">
						In the meantime, please check the <Link className="text-blue-600 hover:underline hover:text-blue-700" to="/home/support">Support</Link> page as you may find some answers there.
					</p>
				</div>}
				{submitted === false && <div className="grid grid-cols-1 max-w-2xl mt-10">
					<Label
						htmlFor="feedback"
						className="text-gray-600 text-lg"
					>
						Please feel free to provide any feedback, questions, issues, concerns, or ideas!
					</Label>
					<textarea
						id="feedback"
						name="feedback"
						value={data}
						onChange={(e) => setData(e.target.value)}
						rows={10}
						className="w-full rounded-lg bg-gray-200 p-2 text-lg mt-2"
						autoFocus
					>
					</textarea>
					<Button
						className="mt-4"
						onClick={submitFeedback}
						disabled={data.length === 0}
					>
						Submit Feedback
					</Button>
				</div>}
			</div>
		</div>
	)
}
