import { useState } from 'react'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, DocumentTextIcon, DevicePhoneMobileIcon, BookOpenIcon, CreditCardIcon, CheckCircleIcon } from '@heroicons/react/24/outline'

// const navigation = [
// 	{ name: 'Product', href: '#' },
// 	{ name: 'Features', href: '#' },
// 	{ name: 'Marketplace', href: '#' },
// 	{ name: 'Company', href: '#' },
// ]

const features = [
	{
		name: 'Generate Forms',
		description:
			'The site can generate the NOIM, the Dec/Cert Combo, and even the Marriage Certificate.',
		icon: DocumentTextIcon,
	},
	{
		name: 'Responsive Design',
		description:
			'Celebrant Briefcase will work in any browser with javascript support (Chrome, Safari, Firefox, Edge, Internet Explorer 10 and above), and on any screen size.',
		icon: DevicePhoneMobileIcon,
	},
	{
		name: '256 Bit Encryption',
		description:
			'Every connection to the site is encrypted and secure.',
		icon: LockClosedIcon,
	},
	{
		name: 'Invoicing',
		description:
			'Clicking a button is all it takes to send your happy couple an invoice. Your invoices can be easily customized, and partial payments are supported.',
		icon: BookOpenIcon,
	},
	{
		name: 'No Lock in Contracts',
		description:
			'Celebrant Briefcase licences run in 6 month and 12 month blocks. If you decide the service is not for you, your weddings can be accessed, and your forms regenerated, at any time, even without a current licence.',
		icon: CreditCardIcon,
	},
	{
		name: 'Cloud',
		description:
			'Everything is stored in the cloud. There is no need for licence keys, downloading of updates, or worrying about backups. Celebrant Briefcase takes care of all of this for you.',
		icon: CloudArrowUpIcon,
	},
]

const tiers = [
	{
		name: '6 Months',
		id: '6-months',
		price: { annually: '$49.50 AUD' },
		features: [
			'Unlimited Weddings',
			'Unlimited Form Generation',
			'Access from any web browser on any screen size',
			'6 Months'
		],
	},
	{
		name: '12 Months',
		id: '12-months',
		price: { annually: '$99.00 AUD' },
		features: [
			'Unlimited Weddings',
			'Unlimited Form Generation',
			'Access from any web browser on any screen size',
			'12 Months'
		],
	},
]


export default function Example() {

	return (
		<div className="container">
			<div className="relative isolate px-6 pt-20 lg:px-8 pb-8">
				<div
					className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
					aria-hidden="true"
				>
					<div
						className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
						style={{
							clipPath:
								'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
						}}
					/>
				</div>
				<div className="text-center">
					<img
						className="mx-auto h-24 w-auto"
						src="/logo.png"
						alt="Celebrant Briefcase"
					/>
					<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mt-16">
						Celebrant Briefcase
					</h1>
					<p className="mt-2 text-lg leading-8 text-gray-600">
						Designed for the Australian celebrant
					</p>
					<div className="mt-14 flex items-center justify-center gap-x-6">
						<a
							data-testid="login"
							href="/login"
							className="rounded-md bg-blue-600 px-6 py-4 text-md font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Signup/Login
						</a>
					</div>
					<div className="mt-24">
						<h2 className="text-4xl underline">
							The Platform
						</h2>
					</div>
					<div className="mt-10 font-light text-gray-700 text-md">
						<p>
							Welcome to Celebrant Briefcase – the ultimate solution for simplifying the paperwork process for your upcoming marriage.
						</p>
						<p>
							As a celebrant, you have enough on your plate without having to worry about the time-consuming task of filling out government forms. That's where we come in.
						</p>
					</div>
					<div className="mt-8 font-light text-gray-700 text-md">
						<p>
							Our platform offers a user-friendly interface for you to easily enter information about the couple and generate the required forms – the Form 15, the Dec/Cert combo, and the Notice of Intended Marriage – all in one convenient place.
						</p>
					</div>
					<div className="mt-8 font-light text-gray-700 text-md">
						<p>
							With Celebrant Briefcase, you can say goodbye to the hassle of manual form-filling and hello to an efficient and stress-free process. Our platform is mobile-friendly, allowing you to complete the paperwork from anywhere, at any time, and on any device. And with our secure system, you can have peace of mind knowing that the personal information of your clients is protected.
						</p>
					</div>
					<div className="mt-8 font-light text-gray-700 text-md">
						<p>
							Don't let paperwork stand in the way of a smooth and successful marriage ceremony. Choose Celebrant Briefcase and make the process a breeze. Try it out today and take the first step towards a streamlined and stress-free celebration.
						</p>
					</div>
					<div className="mt-24">
						<h2 className="text-4xl underline">
							Features
						</h2>
					</div>
					<div className="mt-14">
						<div className="mx-auto max-w-7xl px-6 lg:px-8">
							<div className="mx-auto max-w-2xl lg:max-w-none">
								<dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
									{features.map((feature) => (
										<div key={feature.name} className="flex flex-col items-center text-center">
											<div className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900 text-center">
												<feature.icon className="h-8 w-8 flex-none text-indigo-600" aria-hidden="true" />
												{feature.name}
											</div>
											<div className="mt-4 flex flex-auto flex-col text-base leading-7 font-light text-gray-700 text-md">
												<p className="flex-auto">{feature.description}</p>
											</div>
										</div>
									))}
								</dl>
							</div>
						</div>
					</div>
					<div className="mt-24">
						<h2 className="text-4xl underline">
							Pricing
						</h2>
					</div>
					<p className="mt-1 text-sm font-semibold">
						*Possible discounts for partnered platforms
					</p>
					<p className="mt-0 text-sm font-semibold text-green-500">
						*All new users get a 2 week trial
					</p>
					<div className="pt-8">
						<div className="mx-auto max-w-7xl px-6 lg:px-8">
							<div className="flow-root">
								<div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-2 lg:divide-x lg:divide-y-0 xl:-mx-4">
									{tiers.map((tier) => (
										<div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
											<h3 id={tier.id} className="text-3xl font-semibold leading-7 text-gray-900 text-center">
												{tier.name}
											</h3>
											<p className="mt-2 flex gap-x-1 text-center">
												<span className="text-xl font-semibold tracking-tight text-gray-900 w-full text-center">{tier.price.annually}</span>
											</p>
											<ul role="list" className="mt-6 space-y-3 text-md leading-6 text-gray-600 w-full text-center">
												{tier.features.map((feature) => (
													<li key={feature} className="gap-x-3 w-full font-light text-gray-700 text-md">
														{/* <CheckCircleIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" /> */}
														{feature}
													</li>
												))}
											</ul>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="mt-40">
						<p className="font-light text-gray-700 text-md">
							©2024 Copyright: Nickolas Brekalo
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
