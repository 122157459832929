
import * as Sentry from "@sentry/react";

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
	createBrowserRouter,
	Navigate,
	RouterProvider,
} from "react-router-dom";

// import LandingPage from './pages/LandingPage';
import LoginPage from './pages/auth/LoginPage';
// import RegisterPage from './pages/auth/RegisterPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import WeddingPage from './pages/home/WeddingsPage';
import HomePage from './pages/home/HomePage';
import LogoutPage from './pages/auth/LogoutPage';
import EmailVerifyPage from './pages/auth/EmailVerifyPage';
import RegisterPage from './pages/auth/RegisterPage';
import Form15PrintersPage from './pages/home/Form15PrintersPage';
import SettingsPage from './pages/home/SettingsPage';
import FeedbackPage from './pages/home/FeedbackPage';
import SupportPage from './pages/home/SupportPage';
import AfccPage from './pages/home/AfccPage';
import PublicWeddingPage from './pages/home/PublicWeddingPage';
import LandingPage from './pages/LandingPage';

Sentry.init({
	dsn: "https://5a54de1b3a893812bc1d62f5107ff466@o216948.ingest.us.sentry.io/4507445697314816",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
	{
		path: "/",
		element: <LandingPage />,
	},
	{
		path: "/login",
		element: <LoginPage />,
	},
	{
		path: "/wedding/:id",
		element: <PublicWeddingPage />,
	},
	{
		path: "/identity/password_reset/edit",
		element: <ChangePasswordPage />
	},
	{
		path: "/identity/email_verification",
		element: <EmailVerifyPage />
	},
	{
		path: "/register",
		element: <RegisterPage />
	},
	{
		path: "/forgot-password",
		element: <ForgotPasswordPage />
	},
	{
		path: "/change-password",
		element: <ChangePasswordPage />
	},
	{
		path: "/logout",
		element: <LogoutPage />
	},
	{
		path: "/home",
		element: <HomePage />,
		children: [
			{
				path: "/home/weddings",
				element: <WeddingPage />
			},
			{
				path: "/home/settings",
				element: <SettingsPage />
			},
			{
				path: "/home/printers",
				element: <Form15PrintersPage />
			},
			{
				path: "/home/feedback",
				element: <FeedbackPage />
			},
			{
				path: "/home/support",
				element: <SupportPage />
			},
			{
				path: "/home/afcc",
				element: <AfccPage />
			},
		]
	},
]);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
