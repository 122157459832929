import { url } from "./environment"
import { getToken } from "./token"

export async function FetchRequestGET(path: string): Promise<any> {
	const fullUrl = url() + path
	//
	let headers: any = {
		'Content-Type': 'application/json',
	}
	if (getToken()) {
		headers['Authorization'] = `Bearer ${getToken()}`
	}
	//
	return fetch(fullUrl, {
		// credentials: 'same-origin', // 'include', default: 'omit'
		method: 'GET',             // 'GET', 'PUT', 'DELETE', etc.
		// body: JSON.stringify(data), // Use correct payload (matching 'Content-Type')
		headers: headers
	})
}

export async function FetchRequestPOST(path: string, data: any): Promise<any> {
	const fullUrl = url() + path
	//
	let headers: any = {
		'Content-Type': 'application/json',
	}
	if (getToken()) {
		headers['Authorization'] = `Bearer ${getToken()}`
	}
	//
	return fetch(fullUrl, {
		// credentials: 'same-origin', // 'include', default: 'omit'
		method: 'POST',             // 'GET', 'PUT', 'DELETE', etc.
		body: JSON.stringify(data), // Use correct payload (matching 'Content-Type')
		headers: headers
	})
}

export async function FetchRequestPOSTWithHeaders(path: string, headers: any, data: any): Promise<any> {
	const fullUrl = url() + path
	//
	headers['Content-Type'] = 'application/json'
	//
	if (getToken()) {
		headers['Authorization'] = `Bearer ${getToken()}`
	}
	//
	return fetch(fullUrl, {
		// credentials: 'same-origin', // 'include', default: 'omit'
		method: 'POST',             // 'GET', 'PUT', 'DELETE', etc.
		body: data, // Use correct payload (matching 'Content-Type')
		headers: headers
	})
}

export async function FetchRequestDELETE(path: string): Promise<any> {
	const fullUrl = url() + path
	//
	let headers: any = {
		'Content-Type': 'application/json',
	}
	if (getToken()) {
		headers['Authorization'] = `Bearer ${getToken()}`
	}
	//
	return fetch(fullUrl, {
		// credentials: 'same-origin', // 'include', default: 'omit'
		method: 'DELETE',             // 'GET', 'PUT', 'DELETE', etc.
		// body: JSON.stringify(data), // Use correct payload (matching 'Content-Type')
		headers: headers
	})
}

export async function FetchRequestPATCH(path: string, data: any): Promise<any> {
	const fullUrl = url() + path
	//
	let headers: any = {
		'Content-Type': 'application/json',
	}
	if (getToken()) {
		headers['Authorization'] = `Bearer ${getToken()}`
	}
	//
	return fetch(fullUrl, {
		// credentials: 'same-origin', // 'include', default: 'omit'
		method: 'PATCH',             // 'GET', 'PUT', 'DELETE', etc.
		body: JSON.stringify(data), // Use correct payload (matching 'Content-Type')
		headers: headers
	})
}

export async function FetchRequestPUT(path: string, data: any): Promise<any> {
	const fullUrl = url() + path
	//
	let headers: any = {
		'Content-Type': 'application/json',
	}
	if (getToken()) {
		headers['Authorization'] = `Bearer ${getToken()}`
	}
	//
	return fetch(fullUrl, {
		// credentials: 'same-origin', // 'include', default: 'omit'
		method: 'PUT',             // 'GET', 'PUT', 'DELETE', etc.
		body: JSON.stringify(data), // Use correct payload (matching 'Content-Type')
		headers: headers
	})
}