import { useEffect, useState } from "react"
import { User } from "../../../backend/models/user"
import { GetSession } from "../lib/backend/auth"
import { useNavigate } from "react-router-dom";
import { UserShow } from "../lib/backend/user";

import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert"
import { AlertCircle } from "lucide-react";

export function InactiveMessage() {

	const [initialLoading, setInitialLoading] = useState(true)
	const [user, setUser] = useState<{ id: number, manager: User["manager"], status: User["status"] }>({ id: 0, manager: "", status: "A" })

	const navigate = useNavigate();

	useEffect(() => {
		pageChange()
	}, [])

	async function pageChange() {
		const response = await GetSession()
		if (!response.ok) {
			navigate("/")
		} else {
			const body = await response.json()
			const meResponse = await UserShow(body.session.id)
			if (meResponse.ok) {
				const meBody = await meResponse.json()
				setUser(meBody)
				setInitialLoading(false)
			}
		}
	}
	//
	return (
		<div hidden={initialLoading || user.status === "A" || user.status === "T"} className="my-8">
			<Alert variant="destructive">
				<AlertCircle className="h-4 w-4" />
				<AlertTitle>Inactive Licence</AlertTitle>
				<AlertDescription>
					<p className="my-2">
						Your licence has expired. You can purchase another licence in the settings area.
					</p>
					<p>
						Without a licence you will not be able to create/update weddings or printers. However, you can still print all forms from your weddings.
					</p>
				</AlertDescription>
			</Alert>
		</div>
	)
}
