import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function UserIndex() {
	return FetchRequestGET("/users")
}

export async function UserAFCCIndex() {
	return FetchRequestGET("/users-afcc")
}

export async function UserAFCCLicence() {
	return FetchRequestGET("/users-afcc-licences")
}

export async function UserAFCCActivate(id: number) {
	return FetchRequestGET(`/users-afcc-activate/${id}`)
}

export async function UserCreatePaymentIntent(amountToPay: number) {
	return FetchRequestGET(`/users-create-intent/${amountToPay}`)
}

export async function UserGetMe() {
	return FetchRequestGET("/users/me")
}

export async function UserUpdateMe(body: any) {
	return FetchRequestPATCH(
		"/users/me",
		body
	)
}

export async function UserShow(id: number) {
	return FetchRequestGET(`/users/${id}`)
}

export async function UserUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/users/${id}`,
		body
	)
}

export async function UserCreate(body: any) {
	return FetchRequestPOST(
		`/users`,
		body
	)
}

