import { useEffect, useState } from "react"
import { WeddingShowPublic, WeddingUpdatePublic } from "../../lib/backend/wedding"
import { Wedding } from "../../../../backend/models/wedding"
import { Button } from "../../components/ui/button"
import { useToast } from "../../components/ui/use-toast"
import { WeddingForm } from "../../components/WeddingForm"
import { Invoice } from "../../../../backend/models/invoice"
import { Toaster } from "../../components/ui/toaster"

type WeddingTableRow = Wedding & { invoices: Invoice[] }

export default function WeddingsPage() {

	const [initialLoading, setInitialLoading] = useState(true)
	const [weddingID, setWeddingID] = useState(0)
	const [currentWedding, setCurrentWedding] = useState<WeddingTableRow>(defaultRow)
	const [originalWedding, setOriginalWedding] = useState<WeddingTableRow>(defaultRow)
	const [errorMessage, setErrorMessage] = useState<string>("")

	const { toast } = useToast()

	useEffect(() => {
		getFilesData()
	}, [])

	async function getFilesData() {
		//
		//@ts-ignore
		const weddingIDTemp = parseInt(window.location.pathname.split("/").at(-1))
		//
		setWeddingID(weddingIDTemp)
		//
		const myWeddingsResponse = await WeddingShowPublic(weddingIDTemp)
		if (myWeddingsResponse.ok) {
			//
			const myWeddingsBody = await myWeddingsResponse.json()
			//
			setCurrentWedding(myWeddingsBody)
			setOriginalWedding(myWeddingsBody)
			setInitialLoading(false)
			//
		} else {
			//
			const myWeddingsBody = await myWeddingsResponse.json()
			//
			setErrorMessage(myWeddingsBody.error)
			setInitialLoading(false)
			//
		}
		//
	}

	async function submitData() {
		//
		const myWeddingsResponse = await WeddingUpdatePublic(weddingID, currentWedding)
		if (myWeddingsResponse.ok) {
			//
			const myWeddingsBody = await myWeddingsResponse.json()
			//
			setCurrentWedding(myWeddingsBody)
			setOriginalWedding(myWeddingsBody)
			//
			toast({
				// title: "Settings Saved",
				duration: 1500,
				description: <div className="h-auto text-xl text-green-600 font-bold">
					Wedding saved. Thank you.
				</div>,
			})
			//
		} else {
			//
			const myWeddingsBody = await myWeddingsResponse.json()
			//
			setErrorMessage(myWeddingsBody.error)
			//
		}
		//

	}

	return (
		<div className="px-2 mt-4">
			<div>
				<div

					className="text-2xl font-semibold mb-4 flex justify-between mx-auto mt-8"
				>
					<h1
						className="text-3xl font-bold text-center mx-auto"
						hidden={errorMessage.length > 0}
					>
						Wedding
					</h1>
				</div>
				<div
					className="w-full text-center flex justify-center"
					hidden={!initialLoading}
				>
					<div className="w-20 h-20 mt-8" hidden={!initialLoading}>
						<svg aria-hidden="true" className="w-full text-left h-full m-0 mt-6 mb-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
							<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
						</svg>
					</div>
				</div>
				<div
					hidden={errorMessage.length === 0}
					className="w-full flex justify-center"
				>
					<h1 className="text-2xl font-semibold mt-8">
						{errorMessage}
					</h1>
				</div>
				<div hidden={initialLoading || errorMessage.length > 0} className="mt-8 w-full">
					<div className="w-full flex justify-center">
						<Button
							className="text-xl mb-8"
							disabled={JSON.stringify(currentWedding) === JSON.stringify(originalWedding)}
							onClick={submitData}
						>
							Submit Wedding
						</Button>
					</div>
					<WeddingForm
						weddingData={currentWedding}
						setWeddingData={setCurrentWedding}
						invoiceChanges={[]}
						setInvoiceChanges={() => { }}
						status={"A"}
						mode={"Public"}
					/>
					<div className="w-full flex justify-center">
						<Button
							className="text-xl mt-4 mb-12"
							disabled={JSON.stringify(currentWedding) === JSON.stringify(originalWedding)}
							onClick={submitData}
						>
							Submit Wedding
						</Button>
					</div>
				</div>
			</div>
			<Toaster />
		</div>
	)
}

const defaultRow: Wedding & { invoices: Invoice[] } = {

	id: 0,

	user_id: 0,

	// General
	general_wedding_name: "",
	general_archive_wedding: "No",
	general_public_link_locked: "Yes",
	general_wedding_notes: "",
	general_form15_cert_number: "",

	// Wedding Information
	// Event Details
	//@ts-ignore
	wedding_information_event_details_marriage_time: null,
	//@ts-ignore
	wedding_information_event_details_marriage_date: null,
	wedding_information_event_details_marriage_location: "",
	wedding_information_event_details_marriage_city: "",
	wedding_information_event_details_marriage_postcode: "",
	wedding_information_event_details_marriage_state_australia: "",
	wedding_information_event_details_marriage_state_other: "",
	wedding_information_event_details_leave_place_solemnized_empty: "No",

	// Previously Married
	//@ts-ignore
	wedding_information_person_1_date_last_marriage_ended: null,
	//@ts-ignore
	wedding_information_person_2_date_last_marriage_ended: null,

	// Dec/Cert
	wedding_information_marriage_witness_1: "",
	wedding_information_marriage_witness_2: "",
	wedding_information_person_1_declaration_place: "",
	wedding_information_person_2_declaration_place: "",
	//@ts-ignore
	wedding_information_person_1_declaration_date: null,
	//@ts-ignore
	wedding_information_person_2_declaration_date: null,
	wedding_information_ceremony_used: "civil ceremony according to the Marriage Act 1961",

	// Extra NOIM Info
	//@ts-ignore
	wedding_information_extra_noim_date_noim_received_by_celebrant: null,
	wedding_information_extra_noim_rites_used: "",
	wedding_information_extra_noim_marriage_witness_1: "",
	wedding_information_extra_noim_marriage_witness_2: "",
	wedding_information_extra_noim_authorized_witness_1: "",
	wedding_information_extra_noim_authorized_witness_2: "",

	// Under 18
	wedding_information_person_1_under_18: "No",
	wedding_information_person_2_under_18: "No",
	wedding_information_person_1_under_18_court_name: "",
	wedding_information_person_2_under_18_court_name: "",
	wedding_information_person_1_under_18_court_location: "",
	wedding_information_person_2_under_18_court_location: "",
	//@ts-ignore
	wedding_information_person_1_under_18_court_date: null,
	//@ts-ignore
	wedding_information_person_2_under_18_court_date: null,
	wedding_information_person_1_under_18_consent_received: "No",
	wedding_information_person_2_under_18_court_approval: "No",

	// Parties Related/Signatures
	wedding_information_parties_related: "No",
	wedding_information_relationship: "",
	//@ts-ignore
	wedding_information_person_1_noim_signature_date: null,
	//@ts-ignore
	wedding_information_person_2_noim_signature_date: null,

	// Party 1/2
	party12_person_1_description: "Partner",
	party12_person_2_description: "Partner",
	party12_person_1_surname: "",
	party12_person_2_surname: "",
	party12_person_1_sex: "Non-binary",
	party12_person_2_sex: "Non-binary",
	party12_person_1_first_name: "",
	party12_person_2_first_name: "",
	party12_person_1_occupation: "",
	party12_person_2_occupation: "",
	party12_person_1_address_1: "",
	party12_person_1_address_2: "",
	party12_person_2_address_1: "",
	party12_person_2_address_2: "",
	party12_person_1_city: "",
	party12_person_2_city: "",
	party12_person_1_postcode: "",
	party12_person_2_postcode: "",
	party12_person_1_state: "",
	party12_person_2_state: "",
	party12_person_1_state_other: "",
	party12_person_2_state_other: "",
	party12_person_1_town_of_birth: "",
	party12_person_2_town_of_birth: "",
	party12_person_1_birthplace_state: "",
	party12_person_2_birthplace_state: "",
	party12_person_1_birthplace_state_other: "",
	party12_person_2_birthplace_state_other: "",
	party12_person_1_conjugal: "Never validly married",
	party12_person_2_conjugal: "Never validly married",
	//@ts-ignore
	party12_person_1_dob: null,
	//@ts-ignore
	party12_person_2_dob: null,
	party12_person_1_parent_1_full_current_name: "",
	party12_person_2_parent_1_full_current_name: "",
	party12_person_1_parent_1_full_birth_name: "",
	party12_person_2_parent_1_full_birth_name: "",
	party12_person_1_parent_2_full_current_name: "",
	party12_person_2_parent_2_full_current_name: "",
	party12_person_1_parent_2_full_birth_name: "",
	party12_person_2_parent_2_full_birth_name: "",
	party12_person_1_parent_1_country_of_birth: "",
	party12_person_2_parent_1_country_of_birth: "",
	party12_person_1_parent_2_country_of_birth: "",
	party12_person_2_parent_2_country_of_birth: "",

	// Noim Particulars
	noim_particulars_person_1_evidence: "Birth certificate or official extract",
	noim_particulars_person_2_evidence: "Birth certificate or official extract",
	noim_particulars_person_1_evidence_document_number: "",
	noim_particulars_person_2_evidence_document_number: "",
	noim_particulars_person_1_identity_confirmed: "No",
	noim_particulars_person_2_identity_confirmed: "No",
	noim_particulars_person_1_type_of_id: "",
	noim_particulars_person_2_type_of_id: "",
	noim_particulars_person_1_id_document_number: "",
	noim_particulars_person_2_id_document_number: "",
	noim_particulars_person_1_state_territory_or_country: "",
	noim_particulars_person_2_state_territory_or_country: "",
	noim_particulars_person_1_how_last_marriage_ended: "",
	noim_particulars_person_2_how_last_marriage_ended: "",
	noim_particulars_person_1_death_certificate: "",
	noim_particulars_person_2_death_certificate: "",
	noim_particulars_person_1_court_location: "",
	noim_particulars_person_2_court_location: "",
	noim_particulars_person_1_document_referred: "No",
	noim_particulars_person_2_document_referred: "No",
	noim_particulars_late_marriage_not_applicable: "Off",

	payment_total: 0,
	payment_received: 0,

	invoices: []

}
